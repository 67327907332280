/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { displayValuePropType, searchHitPropType } from "../models/propTypes";
import { shortString } from "../utils/searchTableUtils";
import PrimaryIndicator from "./utilities/primaryIndicator";
import UnpublishedIndicator from "./utilities/unpublishedIndicator";

const hrString = (outcome) => {
  let hr = ``;
  if (outcome.hr) {
    hr = `${outcome.hr}`;
  }
  return hr;
};

const ciString = (outcome) => {
  let ci = ``;
  if (outcome.lowerCI || outcome.upperCI) {
    if (outcome.lowerCI && outcome.upperCI) {
      ci = ` (${outcome.lowerCI}-${outcome.upperCI})`;
    } else if (outcome.lowerCI) {
      ci = ` (${outcome.lowerCI}-)`;
    } else if (outcome.upperCI) {
      ci = ` (-${outcome.upperCI})`;
    }
  }
  return ci;
};

class SearchObservationalResultTable extends Component {
  closeButtonClickHandler = () => {
    const { callbackModal, data } = this.props;
    callbackModal(data.content.morningsideSummary);
  };

  firstOutcomesRow() {
    const { data } = this.props;
    if (data.content.outcomes.length > 0) {
      if (data.content.outcomes[0].outcomes.length > 0) {
        const firstOutcome = data.content.outcomes[0].outcomes[0];
        return (
          <>
            <td
              rowSpan={data.content.outcomes[0].outcomes.length}
              className="search-results-table__striped_cell"
            >
              {data.content.outcomes[0].strata} :{" "}
              {data.content.outcomes[0].varName}
            </td>
            <td className="search-results-table__striped_cell">
              {firstOutcome.type}
              {firstOutcome.primary ? <PrimaryIndicator /> : null}
            </td>
            <td className="search-results-table__striped_cell">
              {hrString(firstOutcome)}
              <br />
              {ciString(firstOutcome)}
            </td>
            <td className="search-results-table__striped_cell">
              {firstOutcome.total}
            </td>
          </>
        );
      }
    }
    return (
      <td colSpan={4} className="search-results-table__striped_cell">
        &nbsp;
      </td>
    );
  }

  restOfFirstOutcomeRows() {
    const { data } = this.props;
    if (
      data.content.outcomes.length > 0 &&
      data.content.outcomes[0].outcomes.length > 1
    ) {
      return data.content.outcomes[0].outcomes.slice(1).map((outcome) => {
        return (
          <tr id={2} key={outcome.type}>
            <td className="search-results-table__striped_cell">
              {outcome.type}
              {outcome.primary ? <PrimaryIndicator /> : null}
            </td>
            <td className="search-results-table__striped_cell">
              {hrString(outcome)}
              <br />
              {ciString(outcome)}
            </td>
            <td className="search-results-table__striped_cell">&nbsp;</td>
          </tr>
        );
      });
    }
    return undefined;
  }

  restOfOutcomesRows() {
    const { data } = this.props;
    return (
      <>
        {/* each outcome is a row */}
        {data.content.outcomes.slice(1).map((outcome) => {
          return (
            // <React.Fragment> is same as <>
            <React.Fragment key={uuid()}>
              {outcome.outcomes.map((strata) => {
                return (
                  <tr key={uuid()}>
                    {outcome.outcomes.indexOf(strata) === 0 ? (
                      <td
                        rowSpan={outcome.outcomes.length}
                        className="search-results-table__striped_cell"
                      >
                        {outcome.strata} : {outcome.varName}
                      </td>
                    ) : null}
                    <td className="search-results-table__striped_cell">
                      {strata.type}
                      {strata.primary ? <PrimaryIndicator /> : null}
                    </td>
                    <td className="search-results-table__striped_cell">
                      {hrString(strata)}
                      <br />
                      {ciString(strata)}
                    </td>
                    <td className="search-results-table__striped_cell">
                      {strata.total}
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  render() {
    const featuresList = sessionStorage.getItem(`my_features`);
    const { cancerStageMapper, cancerStages, data, type } = this.props;

    if (data.id) {
      let rowCount = 0;
      data.content.outcomes.forEach((outcome) => {
        outcome.outcomes.forEach(() => {
          rowCount += 1;
        });
      });
      if (rowCount === 0) {
        rowCount = 1;
      }

      return (
        <>
          <tr key={data.id}>
            <td rowSpan={rowCount}>
              <Link
                to={{
                  pathname: `/${data.index}/${data.id}`,
                  state: {
                    prevPath: `${window.location.pathname}${window.location.search}`,
                  },
                }}
              >
                {data.content.publication.pmid || "View "}
              </Link>
              <Link
                to={{
                  pathname: `/${data.index}/${data.id}`,
                  state: {
                    prevPath: `${window.location.pathname}${window.location.search}`,
                  },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Link>
              {featuresList.includes("Remedy Article Editing") ? (
                <>
                  <br />
                  <Link
                    to={{
                      pathname: `/edit-article/${data.index}/${data.id}`,
                      state: {
                        prevPath: `${window.location.pathname}${window.location.search}`,
                      },
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Edit
                  </Link>
                </>
              ) : null}
              {data.content.status !== "Published" &&
              (featuresList.includes("Remedy Article Editing") ||
                featuresList.includes("Remedy Article Publishing")) ? (
                <>
                  {" "}
                  <UnpublishedIndicator />
                </>
              ) : null}
              <hr />
              {data.content.publication.publicationYear}
            </td>
            <td rowSpan={rowCount}>
              {type.label}
              {data.content.studySubType ? <hr /> : null}
              {shortString(data.content.studySubType)}
            </td>
            <td rowSpan={rowCount}>
              {data.content.cancerStage ? (
                <>
                  {shortString(
                    cancerStageMapper(cancerStages, data.content.cancerStage)
                  )}
                </>
              ) : (
                `n/a`
              )}
              <hr />
              {data.content.cancerType ? (
                <>
                  {shortString(data.content.cancerType)}
                  <br />
                </>
              ) : (
                `n/a`
              )}
              <hr />
              {data.content.cancerSubType ? (
                <>
                  {shortString(data.content.cancerSubType)}
                  <br />
                </>
              ) : (
                `n/a`
              )}
            </td>
            <td rowSpan={rowCount}>
              {data.content.studyDrug
                ? shortString(data.content.studyDrug)
                : "n/a"}
              <hr />
              {data.content.drugClasses.length > 0
                ? shortString(data.content.drugClasses.join(", "))
                : "n/a"}
            </td>
            <td rowSpan={rowCount}>
              {shortString(data.content.treatmentTiming)}
            </td>
            <td rowSpan={rowCount}>{shortString(data.content.dosage)}</td>
            <td rowSpan={rowCount}>
              {shortString(data.content.concurrentSoc)}
            </td>
            <td rowSpan={rowCount}>
              {shortString(data.content.numberOfPatients)}
            </td>
            {this.firstOutcomesRow()}
            <td rowSpan={rowCount}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    shortString(data.content.morningsideSummary)
                  ),
                }}
              />{" "}
              {data.content.morningsideSummary ? (
                <Button
                  as="a"
                  bsPrefix="text-btn"
                  onClick={() => {
                    this.closeButtonClickHandler();
                  }}
                >
                  Read More
                </Button>
              ) : null}
            </td>
          </tr>
          {this.restOfFirstOutcomeRows()}
          {this.restOfOutcomesRows()}
        </>
      );
    }

    return undefined;
  }
}

SearchObservationalResultTable.propTypes = {
  callbackModal: PropTypes.func,
  data: searchHitPropType,
  type: displayValuePropType,
  cancerStageMapper: PropTypes.func,
  cancerStages: PropTypes.arrayOf(displayValuePropType),
};

SearchObservationalResultTable.defaultProps = {
  callbackModal: () => {},
  data: undefined,
  type: undefined,
  cancerStageMapper: () => {},
  cancerStages: [],
};

export default SearchObservationalResultTable;
