import { Col, Form } from "react-bootstrap";
import CheckboxGroup from "react-checkbox-group";
import ReactSelect from "react-select";
import { v4 as uuid } from "uuid";

export const selectField = (
  label,
  name,
  value,
  enabled,
  options = [],
  changeHandler = () => {}
) => {
  const selectOptions = options.map((option) => {
    const optionValue = typeof option === "string" ? option : option[0];
    const optionLabel = typeof option === "string" ? option : option[1];
    return {
      label: optionLabel,
      value: optionValue,
    };
  });
  const selectedOption =
    selectOptions.find(({ value: optionValue }) => value === optionValue) ??
    null;

  return (
    <Col>
      <Form.Label>{label}</Form.Label>
      <ReactSelect
        placeholder={label}
        name={name}
        options={selectOptions}
        value={selectedOption}
        selectProps={{
          className: "form-select",
        }}
        isClearable
        isSearchable
        onChange={(newSelectedOption) => {
          changeHandler({
            target: {
              name,
              value: newSelectedOption?.value,
            },
          });
        }}
        isDisabled={!enabled}
      />
    </Col>
  );
};

export const yesNoField = (label, name, value, enabled, changeHandler) => {
  const options = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];
  const selectedOption =
    options.find(({ value: optionValue }) => value === optionValue) ?? null;
  return (
    <>
      <Col>
        <Form.Label>{label}</Form.Label>
        <ReactSelect
          placeholder={label}
          name={name}
          options={options}
          value={selectedOption}
          selectProps={{
            className: "form-select",
          }}
          isClearable
          isSearchable
          onChange={(newSelectedOption) => {
            changeHandler({
              target: {
                name,
                value: newSelectedOption?.value,
              },
            });
          }}
          isDisabled={!enabled}
        />
      </Col>
    </>
  );
};

export const textArea = (label, name, value, changeHandler) => {
  return (
    <Col>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows="{4}"
        name={name}
        defaultValue={value}
        onChange={changeHandler}
      />
    </Col>
  );
};

export const inputField = (
  label,
  name,
  value,
  disabled,
  inputType,
  changeHandler
) => {
  const type = inputType === "number" ? "number" : "text";
  return (
    <Col>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        name={name}
        defaultValue={value}
        disabled={!disabled}
        onChange={changeHandler}
      />
    </Col>
  );
};

export const inputFieldRequired = (
  label,
  name,
  value,
  disabled,
  inputType,
  changeHandler
) => {
  const type = inputType === "number" ? "number" : "text";
  return (
    <Col>
      <Form.Label>
        {label}
        <span style={{ color: "#e73c4e" }}>*</span>
      </Form.Label>
      <Form.Control
        type={type}
        name={name}
        defaultValue={value}
        disabled={!disabled}
        onChange={changeHandler}
        required
      />
    </Col>
  );
};

export const dateField = (label, name, value, changeHandler) => (
  <Col>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type="date"
      name={name}
      data-date-format="YYYY-MM-DD"
      defaultValue={value}
      onChange={changeHandler}
    />
  </Col>
);

export const otherInput = (label, name, state, relatedState, handler) =>
  relatedState === "Other"
    ? inputField(label, name, state, true, "text", handler)
    : null;

export const booleanCheckboxInput = (label, name, state, handler) => (
  <Col>
    <Form.Label>{label}</Form.Label>
    <Form.Check
      type="checkbox"
      name={name}
      onChange={handler}
      checked={state}
    />
  </Col>
);

export const checkboxGroupInput = (label, name, state, handler, options) => (
  <Col className="checkbox-group">
    {label && <Form.Label>{label}</Form.Label>}
    <CheckboxGroup name={name} value={state} onChange={handler}>
      {options
        ? (Checkbox) => {
            return options.map((option) => {
              const optionValue =
                typeof option === "string" ? option : option[0];
              const optionLabel =
                typeof option === "string" ? option : option[1];
              const optionId = uuid();
              return (
                <label key={optionValue} htmlFor={optionId} className="w-100">
                  <Checkbox id={optionId} value={optionValue} /> {optionLabel}{" "}
                </label>
              );
            });
          }
        : () => <></>}
    </CheckboxGroup>
  </Col>
);
