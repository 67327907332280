import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FeatureName from "../../models/FeatureName";
import ManagedContent from "../../modules/shared/src/components/ManagedContent";
import { ManagedContentContext } from "../../modules/shared/src/components/ManagedContent/models/ManagedContentContext";
import { useMyEmory } from "../../modules/shared/src/hooks";
import useLoadingContext from "../../modules/shared/src/hooks/useLoadingContext";
import ContentManagementUtil from "../../modules/shared/src/utils/ContentManagementUtil";
import Article from "../../pages/Article";
import EditCaseReport from "../../pages/EditCaseReport";
import EditClinicalTrial from "../../pages/EditClinicalTrial";
import EditObservationalResult from "../../pages/EditObservationalResult";
import FrequentlyAskedQuestions from "../../pages/FrequentlyAskedQuestions";
import Glossary from "../../pages/Glossary";
import Home from "../../pages/Home";
import MyWork from "../../pages/MyWork";
import NewArticle from "../../pages/NewArticle";
import Search from "../../pages/Search";
import Warning from "../../pages/Warning";
import Contactpage from "../../pages/contact";
import DemoPage from "../../pages/demopage";
import "../../styles/App.scss";
import { LoadingSpinner } from "../loading-spinner";
import Navbar from "../navbar";
import { getMyRole, setVersion } from "../utilities/request";
import Footer from "./components/Footer";
import useLegalDisclaimer from "./hooks/useLegalDisclaimer";
import ManagedContentName from "./models/ManagedContentName";

const App = () => {
  const { loadingWrapper } = useLoadingContext({
    renderSpinner: (content) => content,
  });
  const [state, setState] = useState({
    isShibbAuthenticated: null,
    netId: "",
    showContactModal: false,
    version: null,
    features: null,
  });
  const { roleNames } = useMyEmory(state.ppid, "Remedy");
  const [showLegalDisclaimer, renderLegalDisclaimer] = useLegalDisclaimer();

  useEffect(() => {
    setVersion().then(() => {
      setState((previousState) => ({
        ...previousState,
        version: JSON.parse(sessionStorage.getItem(`remedy_version`)),
      }));
    });

    getMyRole().then((roleInfo) => {
      setState((previousState) => ({
        ...previousState,
        isShibbAuthenticated: roleInfo.data.contact.netId !== null,
        netId: roleInfo.data.contact.netId,
        ppid: roleInfo.data.contact.ppid,
        features: roleInfo.data.features,
      }));
    });
  }, []);

  const protectedRoutes = useMemo(() => {
    const { features } = state;
    const warningMessage = "Please check permissions.";

    if (features) {
      if (
        features.includes("Remedy Article Editing") === false &&
        features.includes("Remedy Article Publishing") === false
      ) {
        return (
          <>
            <Route
              exact
              path="/new-article"
              render={() => <Warning message={warningMessage} />}
            />
            <Route
              exact
              path="/my-work"
              render={() => <Warning message={warningMessage} />}
            />
            <Route
              exact
              path="/edit-article/case-report/:id"
              render={() => <Warning message={warningMessage} />}
            />
            <Route
              exact
              path="/edit-article/clinical-trial/:id"
              render={() => <Warning message={warningMessage} />}
            />
            <Route
              exact
              path="/edit-article/observational-result/:id"
              render={() => <Warning message={warningMessage} />}
            />
          </>
        );
      }

      if (
        features.includes("Remedy Article Editing") === true ||
        features.includes("Remedy Article Publishing") === true
      ) {
        return (
          <>
            <Route
              exact
              path="/new-article"
              render={(props) => <NewArticle {...props} />}
            />
            <Route
              exact
              path="/my-work"
              render={(props) => <MyWork {...props} features={features} />}
            />
            <Route
              exact
              path="/edit-article/case-report/:id"
              component={EditCaseReport}
            />
            <Route
              exact
              path="/edit-article/clinical-trial/:id"
              component={EditClinicalTrial}
            />
            <Route
              exact
              path="/edit-article/observational-result/:id"
              component={EditObservationalResult}
            />
          </>
        );
      }
    }

    return undefined;
  }, [state]);

  const navbarLinks = useMemo(() => {
    if (showLegalDisclaimer) {
      return [{ type: "link", path: "", label: "Home" }];
    }

    const links = [
      { type: "link", path: "", label: "Home" },
      { type: "link", path: "multi-search", label: "Search" },
      { type: "link", path: "faq", label: "FAQ" },
      { type: "link", path: "glossary", label: "Glossary" },
      { type: "modal", label: "Contact Us" },
    ];

    const { features } = state;

    if (features !== null) {
      if (features.includes("Remedy Article Editing")) {
        links.push({
          type: "link",
          path: "new-article",
          label: "Add New Article",
        });
      }

      if (features.includes("Remedy Article Editing")) {
        links.push({ type: "link", path: "my-work", label: "My Work" });
      }
    }

    return links;
  }, [showLegalDisclaimer, state]);

  const isContentManager = useMemo(
    () => roleNames.includes("Content Manager"),
    [roleNames]
  );

  const managedContentContext = useMemo(() => {
    return {
      isContentManager,
      editable:
        isContentManager &&
        ContentManagementUtil.isContentManagementModeEnabled(),
    };
  }, [isContentManager]);

  return loadingWrapper(
    <div className="app">
      <ManagedContentContext.Provider value={managedContentContext}>
        <ToastContainer />
        <LoadingSpinner />
        {showLegalDisclaimer ? (
          <>
            <Router>
              <Navbar
                title="ReMedy-Cancer"
                links={navbarLinks}
                version={state.version}
                callbackModal={() =>
                  setState((previousState) => ({
                    ...previousState,
                    showContactModal: true,
                  }))
                }
                netId={state.netId}
                isShibbAuthenticated={state.isShibbAuthenticated}
                isContentManager={isContentManager}
              />
              {renderLegalDisclaimer()}
            </Router>
          </>
        ) : (
          <Router>
            <Navbar
              title="ReMedy-Cancer"
              links={navbarLinks}
              version={state.version}
              callbackModal={() =>
                setState((previousState) => ({
                  ...previousState,
                  showContactModal: true,
                }))
              }
              netId={state.netId}
              isShibbAuthenticated={state.isShibbAuthenticated}
              isContentManager={isContentManager}
            />
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route
                exact
                path="/faq"
                render={() => <FrequentlyAskedQuestions />}
              />
              <Route exact path="/glossary" render={() => <Glossary />} />
              <Route exact path="/contact" render={() => <Contactpage />} />
              <Route
                exact
                path="/multi-search"
                render={(props) => <Search {...props} />}
              />
              <Route
                exact
                path="/case-report/:id"
                render={(props) => <Article {...props} />}
              />
              <Route
                exact
                path="/clinical-trial/:id"
                render={(props) => <Article {...props} />}
              />
              <Route
                exact
                path="/observational-result/:id"
                render={(props) => <Article {...props} />}
              />
              {protectedRoutes}
              <Route exact path="/demo" render={() => <DemoPage />} />
              <Route
                path="*"
                render={() => <Warning message="Path does not exist" />}
              />
            </Switch>
            <Footer />
            <Modal
              size="lg"
              show={state.showContactModal}
              onHide={() =>
                setState((previousState) => ({
                  ...previousState,
                  showContactModal: false,
                }))
              }
              aria-labelledby="contact-modal"
              className="contact-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="contact-modal__title">
                  <ManagedContent
                    name={ManagedContentName.Title}
                    featureName={FeatureName.ContactUsModal}
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ManagedContent
                  name={ManagedContentName.Body}
                  featureName={FeatureName.ContactUsModal}
                />
                <div className="d-flex justify-content-center">
                  <Button
                    variant="danger"
                    className="me-sm-2 text-white"
                    onClick={() =>
                      setState((previousState) => ({
                        ...previousState,
                        showContactModal: false,
                      }))
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    as={Link}
                    to={{ pathname: "https://morningsidecenter.emory.edu/" }}
                    target="_blank"
                  >
                    Proceed
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </Router>
        )}
      </ManagedContentContext.Provider>
    </div>
  );
};

export default App;
