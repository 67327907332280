import { FC, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import NewArticle from "../NewArticle";

type PathParams = {
  id: string;
};

export type EditCaseReportProps = RouteComponentProps<PathParams>;

const EditCaseReport: FC<EditCaseReportProps> = (
  props: EditCaseReportProps
): ReactElement => {
  const { match } = props;
  return (
    <NewArticle
      formType="edit"
      index="case-report"
      articleId={match?.params?.id}
      {...props}
    />
  );
};

export default EditCaseReport;
