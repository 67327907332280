import PropTypes from "prop-types";

export const authorPropType = PropTypes.shape({
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  initials: PropTypes.string,
  affiliations: PropTypes.arrayOf(PropTypes.string),
});

export const abstractTextPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const publicationPropType = PropTypes.shape({
  pmid: PropTypes.string,
  pmidUrl: PropTypes.string,
  doi: PropTypes.string,
  doiUrl: PropTypes.string,
  articleTitle: PropTypes.string,
  journalTitle: PropTypes.string,
  journalAbbrev: PropTypes.string,
  authors: PropTypes.string,
  authorsWithAffiliations: PropTypes.arrayOf(authorPropType),
  publicationYear: PropTypes.string,
  country: PropTypes.string,
  abstractTexts: PropTypes.arrayOf(abstractTextPropType),
});

export const clinicalTrialOutcomePropType = PropTypes.shape({
  outcomeType: PropTypes.string,
  controlGroup: PropTypes.string,
  interventionGroup: PropTypes.string,
  interventionGroupPValue: PropTypes.string,
  primary: PropTypes.bool,
});

export const stratifiedOutcomePropType = PropTypes.shape({
  sfId: PropTypes.string,
  type: PropTypes.string,
  hr: PropTypes.string,
  upperCI: PropTypes.string,
  lowerCI: PropTypes.string,
  total: PropTypes.string,
  deaths: PropTypes.string,
  primary: PropTypes.bool,
});

export const observationalResultOutcomePropType = PropTypes.shape({
  varName: PropTypes.string,
  strata: PropTypes.string,
  outcomes: PropTypes.arrayOf(stratifiedOutcomePropType),
});

export const outcomePropType = PropTypes.oneOfType([
  PropTypes.string,
  observationalResultOutcomePropType,
  clinicalTrialOutcomePropType,
]);

export const studyPropType = PropTypes.shape({
  index: PropTypes.string,
  status: PropTypes.string,
  publication: publicationPropType,
  cancerType: PropTypes.string,
  cancerSubType: PropTypes.string,
  otherCancerSubType: PropTypes.string,
  cancerStage: PropTypes.string,
  studyDrug: PropTypes.string,
  studyDrugs: PropTypes.arrayOf(PropTypes.string),
  drugClass: PropTypes.string,
  drugClasses: PropTypes.arrayOf(PropTypes.string),
  dosage: PropTypes.string,
  treatmentTiming: PropTypes.string,
  concurrentSoc: PropTypes.string,
  numberOfPatients: PropTypes.number,
  morningsideSummary: PropTypes.string,
  ctGovUrl: PropTypes.string,
  outcomes: PropTypes.arrayOf(outcomePropType),
  studySubTypes: PropTypes.arrayOf(PropTypes.string),
  histology: PropTypes.string,
  molecularMarker: PropTypes.string,
});

export const searchHitPropType = PropTypes.shape({
  id: PropTypes.string,
  index: PropTypes.string,
  content: studyPropType,
});

export const displayValuePropType = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  order: PropTypes.number,
});
