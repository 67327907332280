import { FC, ReactElement } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Definitions from "../../components/utilities/definitions";

const Glossary: FC = (): ReactElement => {
  return (
    <>
      <Breadcrumb id="breadcrumb-bar">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/glossary" }} active>
          Glossary
        </Breadcrumb.Item>
      </Breadcrumb>
      <Container id="glossary">
        <h2>Glossary</h2>
        <Definitions />
      </Container>
    </>
  );
};

export default Glossary;
