import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import {
  CachedRequestBuilder,
  generalErrorHandler,
  request,
} from "./requestBuilder";

function errorMessagesFromBody(errorBody) {
  if (errorBody.compositeResponse) {
    return errorBody.compositeResponse
      .flatMap((x) => x.body)
      .filter((x) => x.errorCode !== "PROCESSING_HALTED")
      .map((x) => x.message);
  }
  if (errorBody.status && errorBody.error) {
    const errors = [`${errorBody.status} ${errorBody.error}`];
    if (errorBody.message) errors.push(errorBody.message);
    return errors;
  }
  return ["unknown error"];
}

function saveArticle(requestPromise, callback) {
  return trackPromise(requestPromise)
    .then((res) => {
      if (callback) {
        callback(res.data);
      }
    })
    .catch((error) => {
      const msgs = error.response
        ? errorMessagesFromBody(error.response.data)
        : ["client side error", error.toString()];
      const toastConfig = {
        type: toast.TYPE.ERROR,
        closeOnClick: false,
        autoClose: false,
        draggable: false,
      };
      toast(
        <>
          {msgs.map((msg) => (
            <div key={msg}>{msg}</div>
          ))}
        </>,
        toastConfig
      );
      return null;
    });
}

export function addCaseReport(article, callback) {
  return saveArticle(request().post("/api/case-report", article), callback);
}

export function addClinicalTrial(article, callback) {
  return saveArticle(request().post("/api/clinical-trial", article), callback);
}

export function addObservationalResult(article, callback) {
  return saveArticle(
    request().post("/api/observational-result", article),
    callback
  );
}

export function patchArticleStatus(articleId, status, callback) {
  saveArticle(
    request().patch(`/api/article/${articleId}`, { status }),
    callback
  );
}

export function patchCaseReport(article, callback) {
  saveArticle(
    request().patch(`/api/case-report/${article.sfId}`, article),
    callback
  );
}

export function patchClinicalTrial(article, callback) {
  saveArticle(
    request().patch(`/api/clinical-trial/${article.sfId}`, article),
    callback
  );
}

export function patchObservationalResult(article, callback) {
  saveArticle(
    request().patch(`/api/observational-result/${article.sfId}`, article),
    callback
  );
}

export function submitArticle(id) {
  return trackPromise(request().post(`/api/article/${id}/submit`));
}

// add comment
export function addComment(comment, articlePath) {
  return trackPromise(request().post(`/api${articlePath}/comment`, [comment]));
}

// clear shibb attributes
export function clearShibbAttributes() {
  return trackPromise(request().get("/api/clear-shibb-attributes")).catch(
    (error) => error
  );
}

export const fetchCancerStages = CachedRequestBuilder(
  "/api/enums/cancer-stages",
  "cancer_stage_options"
);
export const fetchCancerStagesV2 = CachedRequestBuilder(
  "/api/enums/cancer-stages/v2",
  "cancer_stage_options"
);
export const fetchTnmStages = CachedRequestBuilder(
  "/api/enums/tnm-stages",
  "tnm_stage_options"
);
export const fetchObservationalTypes = CachedRequestBuilder(
  "/api/enums/observational-study-subtypes",
  "observational_type_options"
);
export const fetchClinicalTypes = CachedRequestBuilder(
  "/api/enums/study-subtypes",
  "study_subtype_options"
);
export const fetchClinicalTypesV2 = CachedRequestBuilder(
  "/api/enums/study-subtypes/v2",
  "study_subtype_options_v2"
);
export const fetchStatuses = CachedRequestBuilder(
  "/api/enums/statuses",
  "status_type_options"
);
export const fetchConcurrentSocs = CachedRequestBuilder(
  "/api/enums/concurrent-socs",
  "concurrent_soc_options"
);
export const fetchTreatmentTimings = CachedRequestBuilder(
  "/api/enums/treatment-timings",
  "treatment_timing_options"
);
export const fetchClinicalTrialTreatmentTimings = CachedRequestBuilder(
  "/api/enums/clinical-trial-treatment-timings",
  "ct_treatment_timing_options"
);
export const fetchObservationalTreatmentTimings = CachedRequestBuilder(
  "/api/enums/observational-treatment-timings",
  "or_treatment_timing_options"
);
export const fetchCancerTypes = CachedRequestBuilder(
  "/api/enums/cancer-types",
  "cancer_type_options"
);
export const fetchDrugs = CachedRequestBuilder(
  "/api/enums/drugs",
  "drug_options"
);
export const fetchDrugClasses = CachedRequestBuilder(
  "/api/enums/drug-classes",
  "drug_class_options"
);
export const fetchCancerTypeMap = CachedRequestBuilder(
  "/api/enums/cancer-type-map",
  "cancer_subtype_options"
);
export const fetchClinicalOutcomeTypes = CachedRequestBuilder(
  "/api/enums/clinical-outcome-types",
  "clinical_outcome_type_options"
);
export const fetchObservationalOutcomeTypes = CachedRequestBuilder(
  "/api/enums/observational-outcome-types",
  "observational_outcome_type_options"
);
export const fetchStudyPopulationSexTypes = CachedRequestBuilder(
  "/api/enums/study-population-sex-types",
  "/study_population_sex_type_options"
);
export const fetchUserBiasTypes = CachedRequestBuilder(
  "/api/enums/user-bias-types",
  "/user_bias_type_options"
);
export const fetchStatisticRatioTypes = CachedRequestBuilder(
  "/api/enums/statistic-ratio-types",
  "/statistic_ratio_type_options"
);
export const fetchConfounders = CachedRequestBuilder(
  "/api/enums/confounders",
  "/confounder_options"
);
export const fetchSurveys = CachedRequestBuilder(
  "/api/enums/surveys",
  "/surveys"
);
export const fetchIndexLabels = CachedRequestBuilder(
  "/api/enums/indices",
  "/index_labels"
);

// fetch pubmed data JSON
export function fetchPubmedData(pmid) {
  return trackPromise(request().get(`/api/pubmed/${pmid}`)).catch(
    generalErrorHandler
  );
}

// get case report
export function getCaseReport(id) {
  return trackPromise(request().get(`/api/case-report/${id}`)).catch(
    (error) => error
  );
}

// get specific clinical trial
export function getClinicalTrial(pathname) {
  return trackPromise(request().get(`/api${pathname}`))
    .then((articleInfo) => {
      return articleInfo;
    })
    .catch((error) => error);
}

// get observational result
export function getObservationalResult(id) {
  return trackPromise(request().get(`/api/${id}`)).catch((error) => error);
}

export function getGlossary() {
  return trackPromise(request().get(`/api/glossary`)).catch((error) => error);
}

export function getArticle(index, id) {
  return trackPromise(request().get(`/api/${index}/${id}`)).catch(
    (error) => error
  );
}

export function getMyWork() {
  return trackPromise(request().get(`/api/my-work`)).catch((error) => error);
}

export async function getMyRole() {
  return trackPromise(request().get(`/api/user-access`))
    .then((myRole) => {
      sessionStorage.setItem(
        "my_features",
        JSON.stringify(myRole.data.features)
      );
      return myRole;
    })
    .catch(generalErrorHandler);
}

// elastic search
export async function search(terms) {
  let query = terms.replace(/[^?]+/, "");
  // PLACEHOLDER
  if (!query.includes("sort=publication.publicationYear.keyword")) {
    query += "&sort=publication.publicationYear.keyword:desc";
  }
  return trackPromise(request().get(`/api/multi-search${query}`)).catch(
    generalErrorHandler
  );
}

// get version number
export function setVersion() {
  return trackPromise(request().get("/api/app-info"))
    .then((appInfo) => {
      if (appInfo.data != null) {
        sessionStorage.setItem(
          "remedy_version",
          JSON.stringify(appInfo.data.version)
        );
      }
      return appInfo?.data?.version;
    })
    .catch((error) => error);
}

export function indexLabel(data, key) {
  const index = data.find((x) => x.name === key);
  return index ? index.label : "";
}
