import moment, { Moment } from "moment";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Loading } from "../../components";
import { LoadingContext } from "../../models/LoadingContext";

const DEFAULT_TIMEOUT_MILLIS = 30_000;
const DEFAULT_TIMEOUT_INTERVAL_MILLIS = 1_000;

export type UseLoadingContextProps = {
  renderSpinner?: (content: ReactElement) => ReactElement;
  timeoutMillis?: number;
  timeoutIntervalMillis?: number;
};

export type IdWithTimestamp = {
  id: string;
  timestamp: Moment;
};

const useLoadingContext = (
  props?: UseLoadingContextProps
): {
  loadingWrapper: (content: ReactElement) => ReactElement;
} => {
  const { renderSpinner, timeoutMillis, timeoutIntervalMillis } = useMemo(
    () => ({
      timeoutMillis: DEFAULT_TIMEOUT_MILLIS,
      timeoutIntervalMillis: DEFAULT_TIMEOUT_INTERVAL_MILLIS,
      ...(props ?? {}),
    }),
    [props]
  );
  const [loadingIds, setLoadingIds] = useState<IdWithTimestamp[]>([]);

  const isLoading = useMemo(() => {
    return loadingIds.length > 0;
  }, [loadingIds]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingIds((previousLoadingIds) => {
        if (previousLoadingIds.length > 0) {
          return previousLoadingIds.filter(
            ({ timestamp }) => moment().diff(timestamp, "ms") < timeoutMillis
          );
        }
        return previousLoadingIds;
      });
    }, timeoutIntervalMillis);
    return () => clearInterval(intervalId);
  }, [timeoutIntervalMillis, timeoutMillis]);

  const setLoading = useCallback((id: string, loading: boolean) => {
    if (loading) {
      setLoadingIds((previousLoadingIds) => {
        return [
          ...previousLoadingIds.filter(
            ({ id: previousId }) => previousId !== id
          ),
          {
            id,
            timestamp: moment(),
          },
        ];
      });
    } else {
      setLoadingIds((previousLoadingIds) =>
        previousLoadingIds.filter(({ id: previousId }) => previousId !== id)
      );
    }
  }, []);

  useEffect(() => {
    if (loadingIds.length > 0) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [loadingIds]);

  const loadingWrapper = useCallback(
    (content: ReactElement) => {
      return (
        <LoadingContext.Provider
          value={{
            isLoading,
            setLoading,
          }}
        >
          {renderSpinner ? (
            renderSpinner(content)
          ) : (
            <>
              {isLoading && <Loading />}
              {content}
            </>
          )}
        </LoadingContext.Provider>
      );
    },
    [isLoading, setLoading, renderSpinner]
  );

  return { loadingWrapper };
};

export default useLoadingContext;
