import React, { Component } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * @deprecated This page is unused in the UI and can probably be removed
 */
class Contactpage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <>
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/contact" }} active>
            Contact Us
          </Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="600"
            height="600"
            src="https://morningsidecenter.emory.edu/"
          />
        </Container>
      </>
    );
  }
}

export default Contactpage;
