import PropTypes from "prop-types";
import { Component } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { booleanCheckboxInput, inputField, selectField } from "../basic-input";
import { fetchClinicalOutcomeTypes } from "../utilities/request";
import "./styles.scss";

const controlGroupInput = (state, handler) =>
  inputField("Control Group", "controlGroup", state, true, "text", handler);

const interventionGroupInput = (state, handler) =>
  inputField(
    "Intervention Group",
    "interventionGroup",
    state,
    true,
    "text",
    handler
  );

const interventionGroupPValueInput = (state, handler) =>
  inputField(
    "Intervention Group P Value",
    "interventionGroupPValue",
    state,
    true,
    "text",
    handler
  );

const primaryToggle = (state, handler) =>
  booleanCheckboxInput("Primary?", "primary", state, handler);

class ClinicalOutcomes extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      outcomes: [],
    };
  }

  async componentDidMount() {
    fetchClinicalOutcomeTypes().then((response) => {
      const { outcomes } = this.props;
      this.setState({
        clinicalOutcomeTypeOptions: response.data,
        outcomes,
      });
    });
  }

  render = () => {
    const { outcomes } = this.state;
    return (
      <Form.Group className="clinical-outcomes">
        <div className="d-flex align-items-center mb-3">
          <h2 className="d-inline mb-0 me-2">Outcomes</h2>
          <Button onClick={this.addOutcome}>Add Outcome</Button>
        </div>
        {outcomes.map((outcome, index) => (
          <Card
            key={outcome.id || index}
            body
            className="clinical-outcomes__outcome rounded"
          >
            <Form.Group>
              <Row>
                {this.outcomeTypeInput(
                  outcome.outcomeType,
                  this.handleOutcomeChange(index)
                )}
                {controlGroupInput(
                  outcome.controlGroup,
                  this.handleOutcomeChange(index)
                )}
                {interventionGroupInput(
                  outcome.interventionGroup,
                  this.handleOutcomeChange(index)
                )}
                {interventionGroupPValueInput(
                  outcome.interventionGroupPValue,
                  this.handleOutcomeChange(index)
                )}
                {primaryToggle(outcome.primary, this.handleCheckChange(index))}
                <Col className="d-flex justify-content-center align-items-center">
                  <Button onClick={this.removeOutcome(index)}>Remove</Button>
                </Col>
              </Row>
            </Form.Group>
          </Card>
        ))}
      </Form.Group>
    );
  };

  outcomeTypeInput = (state, handler) => {
    const { clinicalOutcomeTypeOptions } = this.state;
    return selectField(
      "Outcome Type",
      "outcomeType",
      state,
      true,
      clinicalOutcomeTypeOptions,
      handler
    );
  };

  addOutcome = () => {
    const { outcomes: previousOutcomes } = this.state;
    const outcomes = [...previousOutcomes];
    outcomes.push({
      id: uuid(),
      outcomeType: null,
      controlGroup: null,
      interventionGroup: null,
      interventionGroupPValue: null,
    });
    this.setState({ outcomes }, this.runOnChange);
  };

  removeOutcome = (index) => {
    return () => {
      const { outcomes: previousOutcomes } = this.state;
      const outcomes = [...previousOutcomes].filter((x, i) => i !== index);
      this.setState({ outcomes }, this.runOnChange);
    };
  };

  handleCheckChange = (index) => {
    return (event) => {
      const { outcomes: previousOutcomes } = this.state;
      const outcomes = [...previousOutcomes];
      const outcome = { ...outcomes[index] };
      outcome[event.target.name] = event.target.checked;
      outcomes[index] = outcome;
      this.setState({ outcomes }, this.runOnChange);
    };
  };

  handleOutcomeChange = (index) => {
    return (event) => {
      const { outcomes: previousOutcomes } = this.state;
      const outcomes = [...previousOutcomes];
      const outcome = { ...outcomes[index] };
      outcome[event.target.name] = event.target.value;
      outcomes[index] = outcome;
      this.setState({ outcomes }, this.runOnChange);
    };
  };

  runOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      const { outcomes } = this.state;
      onChange(outcomes);
    }
  };
}

ClinicalOutcomes.propTypes = {
  outcomes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      outcomeType: PropTypes.string,
      controlGroup: PropTypes.string,
      interventionGroup: PropTypes.string,
      interventionGroupPValue: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};

ClinicalOutcomes.defaultProps = {
  outcomes: [],
  onChange: () => {},
};

export default ClinicalOutcomes;
