import { ReactElement, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import LegalDisclaimerModal from "./components/LegalDisclaimerModal";

const COOKIE_EXPIRATION_MILLIS = 24 * 60 * 60 * 1000; // 24 hours

const HIDE_REMEDY_DISCLAIMER_KEY = "hideRemedyDisclaimer";

const useLegalDisclaimer = (): [boolean, () => ReactElement] => {
  const [showModal, setShowModal] = useState<boolean>(true);
  const [cookies, setCookie] = useCookies([HIDE_REMEDY_DISCLAIMER_KEY]);

  useEffect(() => {
    setShowModal(!cookies[HIDE_REMEDY_DISCLAIMER_KEY]);
  }, [cookies]);

  const handleConfirm = useCallback(() => {
    const expires = new Date();
    expires.setTime(expires.getTime() + COOKIE_EXPIRATION_MILLIS);
    setCookie(HIDE_REMEDY_DISCLAIMER_KEY, 1, {
      path: "/",
      expires,
    });
  }, [setCookie]);

  const render = useCallback(
    () => (
      <LegalDisclaimerModal
        onCancel={() => setShowModal(false)}
        onConfirm={handleConfirm}
      />
    ),
    [handleConfirm]
  );

  return [showModal, render];
};

export default useLegalDisclaimer;
