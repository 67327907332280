import _ from "lodash";
import { useCallback, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { v4 as uuid } from "uuid";
import { LoadingContext } from "../../models/LoadingContext";
import useSafeAsync from "../useSafeAsync";

const useLoading = (): ((
  getPromise: Promise<unknown> | (() => Promise<unknown>),
  onFinishLoading?: () => void
) => Promise<unknown>) => {
  const safeAsync = useSafeAsync();
  const { setLoading } = useContext(LoadingContext);

  return useCallback(
    async (getPromise, onFinishLoading): Promise<unknown> => {
      const loadingId = uuid();
      setLoading(loadingId, true);
      let result;
      try {
        let promise = getPromise;
        if (_.isFunction(getPromise)) {
          promise = getPromise();
        }
        result = await safeAsync(trackPromise(promise as Promise<unknown>));
      } finally {
        setLoading(loadingId, false);
      }
      if (onFinishLoading) {
        onFinishLoading();
      }
      return result;
    },
    [safeAsync, setLoading]
  );
};

export default useLoading;
