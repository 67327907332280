import { FC, ReactElement } from "react";
import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureName from "../../models/FeatureName";
import ManagedContent from "../../modules/shared/src/components/ManagedContent";
import homePageImage from "./assets/HomePageImage.png";
import ManagedContentName from "./models/ManagedContentName";

const Home: FC = (): ReactElement => {
  return (
    <>
      <Breadcrumb id="breadcrumb-bar">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} active>
          Home
        </Breadcrumb.Item>
      </Breadcrumb>
      <Container>
        <Row>
          <Col id="homepage-about" md={7} sm={6}>
            <ManagedContent
              name={ManagedContentName.About}
              featureName={FeatureName.Home}
            />
          </Col>
          <Col md={5} sm={6}>
            <Card style={{ width: "18rem" }} id="homepage-card">
              <Card.Img variant="top" src={homePageImage} alt="image" />
              <Card.Body>
                <Card.Title>ReMedy-Cancer Database</Card.Title>
                <Card.Text>
                  Repurposed drug knowledgebase related to cancer treatments
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
