import {
  faBackspace,
  faCircleNotch,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Component } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Editor from "react-medium-editor";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ObservationalOutcomes from "../../components/ObservationalOutcomes";
import {
  checkboxGroupInput,
  dateField,
  inputField,
  inputFieldRequired,
  otherInput,
  selectField,
  textArea,
  yesNoField,
} from "../../components/basic-input";
import ClinicalOutcomes from "../../components/clinical-outcomes";
import NewcastleSurvey from "../../components/newcastleSurvey";
import StatusFlow from "../../components/statusFlow";

import {
  addCaseReport,
  addClinicalTrial,
  addObservationalResult,
  fetchCancerStagesV2,
  fetchCancerTypeMap,
  fetchCancerTypes,
  fetchClinicalTrialTreatmentTimings,
  fetchClinicalTypesV2,
  fetchConcurrentSocs,
  fetchConfounders,
  fetchDrugClasses,
  fetchDrugs,
  fetchIndexLabels,
  fetchObservationalTreatmentTimings,
  fetchObservationalTypes,
  fetchPubmedData,
  fetchStudyPopulationSexTypes,
  fetchTnmStages,
  fetchUserBiasTypes,
  getArticle,
  indexLabel,
  patchCaseReport,
  patchClinicalTrial,
  patchObservationalResult,
} from "../../components/utilities/request";
import MorningsideIndex from "../../models/MorningsideIndex";

const outcomesTypesFromOutcomes = (outcomes) => {
  return [
    ...new Set(outcomes.flatMap((x) => x.outcomes).flatMap((x) => x.type)),
  ];
};

const unflattenOutcomes = (outcomes) => {
  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      return {
        ...rv,
        [x[key]]: [...(rv[[x[key]]] ?? []), x],
      };
    }, {});
  };
  const grouped = groupBy(outcomes, "strata");
  return Object.keys(grouped).map((strata) => {
    return {
      strata,
      variables: grouped[strata],
    };
  });
};

class NewArticle extends Component {
  constructor(props, context) {
    super(props, context);
    window.page = this;
    this.state = {
      // Pubmed
      userPubmedInput: "",
      pubmedRequestInProgress: false,
      pubmedManualEntry: true,
      articleTitle: null,
      journalTitle: null,
      journalAbbrev: null,
      authorsString: null,
      publicationYear: null,
      cases: [],
      outcomes: [],
      observationalOutcomes: [],
      formType: props.formType || "add",
      tnmStages: [],
      studySubTypes: [],
      confounders: [],
      newcastleSurvey: null,
      indexLabels: [],
    };

    this.clearOtherFields = this.clearOtherFields.bind(this);
    this.clearPubmed = this.clearPubmed.bind(this);
    this.getPubmed = this.getPubmed.bind(this);
    this.getExistingArticle = this.getExistingArticle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.surveyHandler = this.surveyHandler.bind(this);
    this.morningsideSummaryHandler = this.morningsideSummaryHandler.bind(this);
  }

  async componentDidMount() {
    const { formType } = this.state;
    Promise.all([
      fetchCancerStagesV2().then((response) =>
        this.setState({ cancerStageOptions: response.data })
      ),
      fetchIndexLabels().then((response) =>
        this.setState({ indexLabels: response.data })
      ),
      fetchCancerTypeMap().then((response) =>
        this.setState({ cancerTypeMap: response.data })
      ),
      fetchCancerTypes().then((response) =>
        this.setState({ cancerTypeOptions: response.data })
      ),
      fetchClinicalTypesV2().then((response) =>
        this.setState({ clinicalTypeOptionsV2: response.data })
      ),
      fetchConcurrentSocs().then((response) =>
        this.setState({ concurrentSocOptions: response.data })
      ),
      fetchDrugs().then((response) =>
        this.setState({ drugOptions: response.data })
      ),
      fetchDrugClasses().then((response) =>
        this.setState({ drugClassOptions: response.data })
      ),
      fetchObservationalTypes().then((response) =>
        this.setState({ observationalTypeOptions: response.data })
      ),
      fetchTnmStages().then((response) =>
        this.setState({ tnmStageOptions: response.data })
      ),
      fetchClinicalTrialTreatmentTimings().then((response) =>
        this.setState({ clinicalTreatmentTimingOptions: response.data })
      ),
      fetchObservationalTreatmentTimings().then((response) =>
        this.setState({ observationalTreatmentTimingOptions: response.data })
      ),
      fetchStudyPopulationSexTypes().then((response) =>
        this.setState({ studyPopulationSexOptions: response.data })
      ),
      fetchUserBiasTypes().then((response) =>
        this.setState({ userBiasOptions: response.data })
      ),
      fetchConfounders().then((response) =>
        this.setState({ confounderOptions: response.data })
      ),
    ]).then(() => {
      if (formType === "edit") {
        this.getExistingArticle();
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { formType } = this.state;
    if (formType === "add") {
      this.postArticle();
    } else if (formType === "edit") {
      this.patchArticle();
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "index") {
      this.clearOtherFields();
    }
  }

  handleYesNoChange = (event) => {
    this.setState({
      [event.target.name]: JSON.parse(event.target.value),
    });
  };

  getPubmed() {
    this.setState({
      pubmedRequestInProgress: true,
    });

    const { userPubmedInput } = this.state;

    return fetchPubmedData(userPubmedInput)
      .then((pubmedInfo) => {
        if (pubmedInfo.status === 200) {
          this.setState({
            pubmedRequestInProgress: false,
          });
          if (pubmedInfo.data === null) {
            this.clearPubmed();
          } else {
            this.setState({
              abstractTexts: pubmedInfo.data.abstractTexts,
              articleTitle: pubmedInfo.data.articleTitle,
              authorsString: pubmedInfo.data.authorsString,
              country: pubmedInfo.data.country,
              doi: pubmedInfo.data.doi,
              journalAbbrev: pubmedInfo.data.journalAbbrev,
              journalTitle: pubmedInfo.data.journalTitle,
              publicationYear: pubmedInfo.data.publicationYear,
              pubmedManualEntry: false,
            });
          }
        } else {
          this.setState({
            pubmedRequestInProgress: false,
          });
        }
      })
      .catch((e) =>
        toast.error(
          <>
            <p>Pubmed Search Failed</p>
            <div>{e.message}</div>
            <div className="stacktrace">{e.stacktrace}</div>
          </>,
          { position: "top-center", closeOnClick: false }
        )
      );
  }

  async getExistingArticle() {
    const { index, articleId } = this.props;
    this.setState({
      articleRequestInProgress: true,
    });
    const articleInfo = await getArticle(index, articleId);
    if (articleInfo.status === 200) {
      this.setState({
        articleRequestInProgress: false,
      });
      if (articleInfo.data === null) {
        this.clearPubmed();
      } else {
        const doc = articleInfo.data;
        this.populateForm(doc);
      }
    } else {
      this.setState({
        articleRequestInProgress: false,
      });
    }
  }

  populateForm = (doc) => {
    switch (doc.index) {
      case MorningsideIndex.CASE_REPORT:
        this.populateCaseReport(doc);
        break;
      case MorningsideIndex.CLINICAL_TRIAL:
        this.populateClinicalTrial(doc);
        break;
      case MorningsideIndex.OBSERVATIONAL_RESULT:
        this.populateObservationalResult(doc);
        break;
      default:
        throw new Error(`unknown index: ${doc.index}`);
    }
  };

  extractPublication = () => {
    const {
      abstractTexts,
      articleTitle,
      authorsString,
      country,
      doi,
      journalAbbrev,
      journalTitle,
      userPubmedInput,
      publicationYear,
    } = this.state;
    return {
      abstractTexts,
      articleTitle,
      authors: authorsString,
      country,
      doi,
      journalAbbrev,
      journalTitle,
      pmid: userPubmedInput,
      publicationYear,
    };
  };

  surveyHandler = (surveyObj) => {
    this.setState({
      newcastleSurvey: surveyObj,
    });
  };

  morningsideSummaryHandler = (summary) => {
    this.setState({
      morningsideSummary: summary,
    });
  };

  populateCaseReport = (doc) =>
    this.setState(
      {
        index: doc.index,
        status: doc.status,
        sfId: doc.sfId,
        rmId: doc.rmId,
        cancerType: doc.cancerType,
        cancerSubType: doc.cancerSubType,
        histology: doc.histology,
        molecularMarker: doc.molecularMarker,
        morningsideSummary: doc.morningsideSummary,
        numberOfPatients: doc.numberOfPatients,
        cases: doc.cases,
      },
      () => this.populatePublication(doc)
    );

  populateClinicalTrial = (doc) =>
    this.setState(
      {
        index: doc.index,
        status: doc.status,
        sfId: doc.sfId,
        rmId: doc.rmId,
        cancerType: doc.cancerType,
        cancerSubType: doc.cancerSubType,
        otherCancerSubType: doc.otherCancerSubType,
        histology: doc.histology,
        molecularMarker: doc.molecularMarker,
        cancerStage: doc.cancerStage,
        tnmStages: doc.tnmStages,
        treatmentTiming: doc.treatmentTiming,
        otherTreatmentTiming: doc.otherTreatmentTiming,
        studySubTypes: doc.studySubTypes,
        otherStudySubType: doc.otherStudySubType,
        concurrentSoc: doc.concurrentSoc,
        soc: doc.soc,
        otherSoc: doc.otherSoc,
        studyDrug: doc.studyDrug,
        otherStudyDrug: doc.otherStudyDrug,
        drugClass: doc.drugClass,
        dosage: doc.dosage,
        numberOfPatients: doc.numberOfPatients,
        outcomes: doc.outcomes,
        morningsideSummary: doc.morningsideSummary,
      },
      () => this.populatePublication(doc)
    );

  populateObservationalResult = (doc) =>
    this.setState(
      {
        index: doc.index,
        status: doc.status,
        sfId: doc.sfId,
        rmId: doc.rmId,

        // Disease Group
        cancerType: doc.cancerType,
        cancerSubType: doc.cancerSubType,
        otherCancerSubType: doc.otherCancerSubType,
        histology: doc.histology,
        molecularMarker: doc.molecularMarker,
        cancerStage: doc.cancerStage,
        tnmStages: doc.tnmStages,

        // Treatment Group
        studyDrug: doc.studyDrug,
        otherStudyDrug: doc.otherStudyDrug,
        drugClass: doc.drugClasses ? doc.drugClasses[0] : null,
        otherDrugClass: doc.otherDrugClass,
        dosage: doc.dosage,
        treatmentTiming: doc.treatmentTiming,
        otherTreatmentTiming: doc.otherTreatmentTiming,
        concurrentSoc: doc.concurrentSoc,
        otherSoc: doc.otherSoc,

        // Observational Study Group
        studySubType: doc.studySubType,
        otherStudySubType: doc.otherStudySubType,
        numberOfPatients: doc.numberOfPatients,
        studyPopulationSex: doc.studyPopulationSex,
        drugUseDuration: doc.drugUseDuration,
        studyStartDate: doc.studyStartDate,
        studyEndDate: doc.studyEndDate,
        userBias: doc.userBias,
        immortalTimeBiasRisk: doc.immortalTimeBiasRisk,
        selectionBiasRisk: doc.selectionBiasRisk,
        confoundingByIndication: doc.confoundingByIndication,
        confounders: doc.confounders,
        qualityConcern: doc.qualityConcern,
        qualityNotes: doc.qualityNotes,

        // Observational Outcomes
        observationalOutcomes: doc.outcomes,
        statisticRatio: doc.statisticRatio,

        // Newcastle
        newcastleSurvey: doc.newcastleSurvey,

        // Morningside Summary Group
        morningsideSummary: doc.morningsideSummary,
      },
      () => this.populatePublication(doc)
    );

  populatePublication = (doc) =>
    this.setState({
      userPubmedInput: doc.publication.pmid,
      articleTitle: doc.publication.articleTitle,
      authorsString: doc.publication.authors,
      country: doc.publication.country,
      doi: doc.publication.doi,
      journalAbbrev: doc.publication.journalAbbrev,
      journalTitle: doc.publication.journalTitle,
      publicationYear: doc.publication.publicationYear,
      abstractTexts: doc.publication.abstractTexts,
      pubmedManualEntry: false,
    });

  extractCaseReport = () => {
    const {
      sfId,
      rmId,
      index,
      cancerType,
      cancerSubType,
      cases,
      morningsideSummary,
      numberOfPatients,
      histology,
      molecularMarker,
    } = this.state;
    return {
      sfId,
      rmId,
      index,
      publication: this.extractPublication(),
      cancerType,
      cancerSubType,
      cases,
      morningsideSummary,
      numberOfPatients,
      histology,
      molecularMarker,
    };
  };

  extractClinicalTrial = () => {
    const {
      sfId,
      rmId,
      index,
      cancerType,
      cancerSubType,
      otherCancerSubType,
      histology,
      molecularMarker,
      cancerStage,
      tnmStages,
      treatmentTiming,
      otherTreatmentTiming,
      studySubTypes,
      otherStudySubType,
      concurrentSoc,
      soc,
      otherSoc,
      studyDrug,
      otherStudyDrug,
      drugClass,
      dosage,
      numberOfPatients,
      outcomes,
      morningsideSummary,
    } = this.state;
    return {
      sfId,
      rmId,
      index,
      publication: this.extractPublication(),
      cancerType,
      cancerSubType,
      otherCancerSubType,
      histology,
      molecularMarker,
      cancerStage,
      tnmStages,
      treatmentTiming,
      otherTreatmentTiming,
      studySubTypes,
      otherStudySubType,
      concurrentSoc,
      soc,
      otherSoc,
      studyDrug,
      otherStudyDrug,
      drugClass,
      dosage,
      numberOfPatients,
      outcomes,
      morningsideSummary,
    };
  };

  extractObservationalResult = () => {
    const {
      sfId,
      rmId,
      index,
      cancerType,
      cancerSubType,
      otherCancerSubType,
      histology,
      molecularMarker,
      cancerStage,
      tnmStages,
      studyDrug,
      otherStudyDrug,
      drugClass,
      drugClasses,
      otherDrugClass,
      dosage,
      treatmentTiming,
      otherTreatmentTiming,
      concurrentSoc,
      otherSoc,
      studySubType,
      otherStudySubType,
      numberOfPatients,
      studyPopulationSex,
      drugUseDuration,
      studyStartDate,
      studyEndDate,
      userBias,
      immortalTimeBiasRisk,
      selectionBiasRisk,
      confoundingByIndication,
      confounders,
      qualityConcern,
      qualityNotes,
      statisticRatio,
      observationalOutcomes,
      morningsideSummary,
      newcastleSurvey,
    } = this.state;
    return {
      sfId,
      rmId,
      index,
      publication: this.extractPublication(),

      // Disease Group
      cancerType,
      cancerSubType,
      otherCancerSubType,
      histology,
      molecularMarker,
      cancerStage,
      tnmStages,

      // Treatment Group
      studyDrug,
      otherStudyDrug,
      drugClasses: [...(drugClasses ?? []), drugClass].filter((x) => !!x),
      otherDrugClass,
      dosage,
      treatmentTiming,
      otherTreatmentTiming,
      concurrentSoc,
      otherSoc,

      // Observational Study Group
      studySubType,
      otherStudySubType,
      numberOfPatients,
      studyPopulationSex,
      drugUseDuration,
      studyStartDate,
      studyEndDate,
      userBias,
      immortalTimeBiasRisk,
      selectionBiasRisk,
      confoundingByIndication,
      confounders,
      qualityConcern,
      qualityNotes,

      // Observational Outcomes
      statisticRatio,
      outcomes: observationalOutcomes,

      // Morningside Summary Group
      morningsideSummary,

      // Newcastle Survey
      newcastleSurvey,
    };
  };

  clearOtherFields = () => {
    this.setState({
      dosage: "",
      numberOfPatients: "",
      treatmentTiming: "",
    });
  };

  clearPubmed = () => {
    this.setState({
      userPubmedInput: "",
      pubmedManualEntry: true,
      abstractTexts: [],
      articleTitle: null,
      authorsString: null,
      country: null,
      doi: null,
      journalAbbrev: null,
      journalTitle: null,
      publicationYear: null,
    });
  };

  patchArticle = async () => {
    const { index, indexLabels } = this.state;

    const callback = () => {
      toast(`${indexLabel(indexLabels, index)} article has been saved.`, {
        type: toast.TYPE.SUCCESS,
      });
    };

    switch (index) {
      case MorningsideIndex.CASE_REPORT:
        patchCaseReport(this.extractCaseReport(), callback);
        break;
      case MorningsideIndex.CLINICAL_TRIAL:
        patchClinicalTrial(this.extractClinicalTrial(), callback);
        break;
      case MorningsideIndex.OBSERVATIONAL_RESULT:
        patchObservationalResult(this.extractObservationalResult(), callback);
        break;
      default:
        throw new Error(`Failed to save to unknown index: ${index}`);
    }
  };

  postArticle = () => {
    const { index, indexLabels } = this.state;

    const callback = (data) => {
      const { study } = data;
      toast(`New ${indexLabel(indexLabels, index)} article has been added.`, {
        type: toast.TYPE.SUCCESS,
      });
      window.history.pushState(
        {},
        document.title,
        `/edit-article/${study.index}/${study.sfId}`
      );
      this.setState({ formType: "edit" }, () => this.populateForm(study));
    };

    switch (index) {
      case MorningsideIndex.CASE_REPORT:
        addCaseReport(this.extractCaseReport(), callback);
        break;
      case MorningsideIndex.CLINICAL_TRIAL:
        addClinicalTrial(this.extractClinicalTrial(), callback);
        break;
      case MorningsideIndex.OBSERVATIONAL_RESULT:
        addObservationalResult(this.extractObservationalResult(), callback);
        break;
      default:
        throw new Error(`Failed to add to unknown index: ${index}`);
    }
  };

  renderStatusFlow = () => {
    const { formType, sfId, status } = this.state;
    if (formType === "add") {
      return null;
    }
    return <StatusFlow currentStatus={status} sfid={sfId} />;
  };

  articleTypeSelector = () => {
    const { index, formType, indexLabels } = this.state;
    if (formType === "add") {
      return (
        <Form.Group>
          <Row>
            {this.selectField(
              "Article Type",
              "index",
              index,
              true,
              indexLabels.map((x) => [x.name, x.label])
            )}
          </Row>
        </Form.Group>
      );
    }
    return undefined;
  };

  pubmedGroup = () => {
    const {
      abstractTexts,
      articleTitle,
      index,
      authorsString,
      country,
      doi,
      journalAbbrev,
      journalTitle,
      pubmedManualEntry,
      pubmedRequestInProgress,
      publicationYear,
      userPubmedInput,
    } = this.state;
    return (
      <div className="article-form__pubmed-fields">
        <h2>Pubmed Fields</h2>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Pubmed ID</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  min="0"
                  onChange={this.handleChange}
                  value={userPubmedInput ?? ""}
                  name="userPubmedInput"
                  disabled={!pubmedManualEntry}
                />
                {pubmedManualEntry ? (
                  <Button
                    onClick={this.getPubmed}
                    disabled={!pubmedManualEntry}
                  >
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Button>
                ) : (
                  <Button onClick={this.clearPubmed}>
                    <FontAwesomeIcon icon={faBackspace} /> Clear
                  </Button>
                )}
              </InputGroup>
            </Col>
          </Row>
        </Form.Group>
        {!pubmedRequestInProgress ? (
          <Form.Group>
            <Row>
              {this.inputFieldRequired(
                `Article Title`,
                "articleTitle",
                articleTitle,
                pubmedManualEntry
              )}
              {this.inputField(
                "Authors",
                "authorsString",
                authorsString,
                pubmedManualEntry
              )}
            </Row>
            <Row>
              {this.inputField(
                "Journal Title",
                "journalTitle",
                journalTitle,
                pubmedManualEntry
              )}
              {this.inputField(
                "Journal Abbreviation",
                "journalAbbrev",
                journalAbbrev,
                pubmedManualEntry
              )}
            </Row>
            <Row>
              {this.inputField(
                "Year",
                "publicationYear",
                publicationYear,
                pubmedManualEntry
              )}
              {this.inputField(
                "Country",
                "country",
                country,
                pubmedManualEntry
              )}
            </Row>
            <Row>{this.inputField("DOI", "doi", doi, pubmedManualEntry)}</Row>
            {!pubmedManualEntry &&
              abstractTexts &&
              abstractTexts.length > 0 && (
                <Row>
                  <Col>
                    <h3>Abstract</h3>
                    <div className="abstract rounded">
                      {abstractTexts.map((section) => (
                        <div key={section.text} className="mb-2">
                          {section.label && <h4>{section.label}</h4>}
                          <p>{section.text}</p>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
          </Form.Group>
        ) : (
          "Loading..."
        )}

        {index === MorningsideIndex.CASE_REPORT && this.caseReportInput()}
        {index === MorningsideIndex.CLINICAL_TRIAL && this.clinicalTrialInput()}
        {index === MorningsideIndex.OBSERVATIONAL_RESULT &&
          this.observationalResultInput()}
      </div>
    );
  };

  // SECTION Article/Study Type Components

  caseReportInput = () => {
    const { morningsideSummary } = this.state;
    return (
      <>
        {this.diseaseGroup(false)}
        {this.casesControl()}
        {this.morningsideSummaryGroup(morningsideSummary)}
      </>
    );
  };

  clinicalTrialInput = () => {
    const { clinicalTreatmentTimingOptions, outcomes, morningsideSummary } =
      this.state;
    return (
      <>
        {this.diseaseGroup(true)}
        {this.treatmentGroup(clinicalTreatmentTimingOptions)}
        {this.clinicalTrialStudyGroup()}
        <ClinicalOutcomes
          outcomes={outcomes}
          onChange={(x) => this.setState({ outcomes: x })}
        />
        {this.morningsideSummaryGroup(morningsideSummary)}
      </>
    );
  };

  observationalResultInput = () => {
    const {
      morningsideSummary,
      observationalOutcomes,
      observationalTreatmentTimingOptions,
      statisticRatio,
    } = this.state;
    return (
      <>
        {this.diseaseGroup(true)}
        {this.treatmentGroup(observationalTreatmentTimingOptions)}
        {this.observationalStudyGroup()}
        <ObservationalOutcomes
          strata={unflattenOutcomes(observationalOutcomes)}
          outcomeTypes={outcomesTypesFromOutcomes(observationalOutcomes)}
          statisticRatio={statisticRatio}
          onChange={this.setObservationalOutcomeState}
        />
        {this.morningsideSummaryGroup(morningsideSummary)}
      </>
    );
  };

  //
  // SECTION Form Groups/Sections

  setObservationalOutcomeState = (
    statisticRatio,
    univariate,
    multivariate,
    stratified
  ) => {
    const allOutcomes = [...stratified];

    if (univariate) {
      allOutcomes.push(univariate);
    }

    if (multivariate) {
      allOutcomes.push(multivariate);
    }

    this.setState({
      observationalOutcomes: allOutcomes
        .map((x) =>
          x.variables.map((y) => ({
            strata: x.strata,
            varName: y.varName,
            outcomes: y.outcomes,
          }))
        )
        .flat(),
      statisticRatio,
    });
  };

  diseaseGroup = (includeStages) => {
    const {
      cancerStage,
      cancerSubType,
      cancerType,
      histology,
      molecularMarker,
      otherCancerSubType,
      tnmStages,
    } = this.state;
    return (
      <Form.Group className="article-form__disease">
        <h2>Disease</h2>
        <Row>
          {this.cancerTypeInput(cancerType)}
          {this.cancerSubTypeInput(cancerSubType, cancerType)}
          {this.otherCancerSubTypeInput(otherCancerSubType, cancerSubType)}
        </Row>
        <Row>{this.histologyInput(histology)}</Row>
        <Row>{this.molecularMarkerInput(molecularMarker)}</Row>
        {includeStages && (
          <>
            <Row>{this.cancerStageInput(cancerStage)}</Row>
            <Row>
              {this.tnmStageInput(tnmStages, (x) =>
                this.setState({ tnmStages: x })
              )}
            </Row>
          </>
        )}
      </Form.Group>
    );
  };

  treatmentGroup = (treatmentTimingOptions) => {
    const {
      concurrentSoc,
      dosage,
      drugClass,
      otherDrugClass,
      otherSoc,
      otherStudyDrug,
      otherTreatmentTiming,
      studyDrug,
      treatmentTiming,
    } = this.state;
    return (
      <Form.Group>
        <h2>Treatment</h2>
        <Row>
          {this.studyDrugInput("studyDrug", studyDrug, (selected) => {
            this.setState({ studyDrug: selected.length ? selected[0] : null });
          })}
          {this.otherDrugInput(otherStudyDrug, studyDrug)}
        </Row>
        <Row>
          {this.drugClassInput(drugClass)}
          {this.otherDrugClassInput(otherDrugClass, drugClass)}
        </Row>
        <Row>{this.dosageInput(dosage)}</Row>
        <Row>
          {this.treatmentTimingInput(treatmentTiming, treatmentTimingOptions)}
          {this.otherTreatmentTimingInput(
            otherTreatmentTiming,
            treatmentTiming
          )}
        </Row>
        <Row>
          {this.concurrentSocInput(concurrentSoc)}
          {this.otherConcurrentSocInput(otherSoc, concurrentSoc)}
        </Row>
      </Form.Group>
    );
  };

  clinicalTrialStudyGroup = () => {
    const { numberOfPatients, studySubTypes } = this.state;
    return (
      <Form.Group>
        <h2>Study</h2>
        <Row>
          {this.studySubTypesInput(studySubTypes, (x) =>
            this.setState({ studySubTypes: x })
          )}
        </Row>
        <Row>{this.numberOfPatientsInput(numberOfPatients)}</Row>
      </Form.Group>
    );
  };

  studySubTypesInput = (state, handler) => {
    const { clinicalTypeOptionsV2 } = this.state;
    return checkboxGroupInput(
      "Study Sub-Types",
      "studySubTypes",
      state,
      handler,
      clinicalTypeOptionsV2.map((x) => [x.name, x.label])
    );
  };

  observationalStudyGroup = () => {
    const {
      confounders,
      confoundingByIndication,
      drugUseDuration,
      immortalTimeBiasRisk,
      numberOfPatients,
      otherStudySubType,
      qualityConcern,
      qualityNotes,
      selectionBiasRisk,
      studyEndDate,
      studyPopulationSex,
      studyStartDate,
      studySubType,
      userBias,
    } = this.state;
    return (
      <Form.Group>
        <h2>Study</h2>
        <Row>
          {this.observationalSubTypeInput(studySubType)}
          {this.otherObservationalSubTypeInput(otherStudySubType, studySubType)}
        </Row>
        <Row>{this.numberOfPatientsInput(numberOfPatients)}</Row>
        <Row>{this.studyPopulationSexInput(studyPopulationSex)}</Row>
        <Row>{this.drugUseDurationInput(drugUseDuration)}</Row>
        <Row>{this.studyStartDateInput(studyStartDate)}</Row>
        <Row>{this.studyEndDateInput(studyEndDate)}</Row>
        <Row>{this.userBiasInput(userBias)}</Row>
        <Row>{this.immortalTimeBiasInput(immortalTimeBiasRisk)}</Row>
        <Row>{this.riskPrevalentBiasInput(selectionBiasRisk)}</Row>
        <Row>{this.confoundingIndicationInput(confoundingByIndication)}</Row>
        <Row>
          {this.confoundersInput(confounders, (x) =>
            this.setState({ confounders: x })
          )}
        </Row>
        <Row>{this.qualityConcernInput(qualityConcern)}</Row>
        <Row>{this.qualityNotesInput(qualityNotes)}</Row>
      </Form.Group>
    );
  };

  morningsideSummaryGroup = () => {
    const { morningsideSummary } = this.state;
    return (
      <Form.Group className="article-form__morningside-summary">
        <h2>Morningside Summary</h2>
        <Row>
          <Col>
            <Form.Label className="text-muted">
              Highlight text to add decoration
            </Form.Label>
            <Editor
              label="Morningside Summary"
              name="morningsideSummary"
              text={morningsideSummary}
              onChange={(text) => this.setState({ morningsideSummary: text })}
              options={{
                toolbar: { buttons: ["bold", "italic", "underline"] },
              }}
              className="article-form__morningside-summary__editor rounded"
            />
          </Col>
        </Row>
      </Form.Group>
    );
  };

  /* SECTION - Single Field Components */

  tnmStageInput = (state, handler) => {
    const { tnmStageOptions } = this.state;
    return checkboxGroupInput(
      "TNM Stages",
      "tnmStages",
      state,
      handler,
      tnmStageOptions
    );
  };

  casesControl = () => {
    const { cases, clinicalTreatmentTimingOptions } = this.state;
    return (
      <Form.Group className="article-form__cases">
        <div className="d-flex align-items-center mb-3">
          <h2 className="d-inline mb-0 me-2">Cases</h2>
          <Button onClick={this.addCase}>Add Case</Button>
        </div>
        {cases.map((_case, index) => (
          <Card
            key={_case.sfId || index}
            body
            className="article-form__cases__case mb-3 rounded"
          >
            <Form.Group>
              <Row>
                {this.cancerStageInput(
                  _case.cancerStage,
                  this.handleCaseChange(index)
                )}
                {this.otherCancerStageInput(
                  _case.otherStage,
                  _case.cancerStage,
                  this.handleCaseChange(index)
                )}
              </Row>
              <Row>
                {this.treatmentTimingInput(
                  _case.treatmentTiming,
                  clinicalTreatmentTimingOptions,
                  this.handleCaseChange(index)
                )}
                {this.otherTreatmentTimingInput(
                  _case.otherTreatmentTiming,
                  _case.treatmentTiming,
                  this.handleCaseChange(index)
                )}
              </Row>
              <Row>
                {this.concurrentSocInput(
                  _case.concurrentSoc,
                  this.handleCaseChange(index)
                )}
                {this.otherConcurrentSocInput(
                  _case.otherSoc,
                  _case.concurrentSoc,
                  this.handleCaseChange(index)
                )}
              </Row>
              <Row>
                {this.studyDrugInput(
                  `case-${index}-studyDrug`,
                  _case.studyDrug,
                  (selected) => {
                    const newCases = [...cases];
                    const c = { ...newCases[index] };
                    c.studyDrug = selected.length ? selected[0] : null;
                    newCases[index] = c;
                    this.setState({ cases: newCases });
                  }
                )}
              </Row>
              <Row>
                {this.dosageInput(_case.dosage, this.handleCaseChange(index))}
              </Row>
              <Row>
                {this.outcomesInput(
                  _case.outcomes,
                  this.handleCaseChange(index)
                )}
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button onClick={this.removeCase(index)}>Remove</Button>
                </Col>
              </Row>
            </Form.Group>
          </Card>
        ))}
      </Form.Group>
    );
  };

  addCase = () => {
    const { cases: previousCases } = this.state;
    const cases = [...previousCases];
    cases.push({
      cancerStage: null,
      concurrentSoc: null,
      dosage: null,
      otherSoc: null,
      otherStage: null,
      otherTreatmentTiming: null,
      outcomes: null,
      studyDrug: null,
      treatmentTiming: null,
    });
    this.setState({ cases });
  };

  removeCase = (index) => {
    return () => {
      const { cases: previousCases } = this.state;
      let cases = [...previousCases];
      cases = cases.filter((x, i) => i !== index);
      this.setState({ cases });
    };
  };

  handleCaseChange = (index) => {
    return (event) => {
      const { cases: previousCases } = this.state;
      const cases = [...previousCases];
      const c = { ...cases[index] };
      c[event.target.name] = event.target.value;
      cases[index] = c;
      this.setState({ cases });
    };
  };

  blurHandler = (e) => {
    window.blurEvent = e;
    const { drugOptions } = this.state;
    const match = drugOptions.find((x) => e.target.value === x);
    e.target.value = match || "";
  };

  isStudyDrugValid = (value) => {
    if (value === null) {
      return false;
    }
    const { drugOptions } = this.state;
    return !!drugOptions.find((x) => value === x);
  };

  studyDrugInput = (id, value, handler) => {
    const { drugOptions } = this.state;
    return (
      <Col>
        <Form.Label>Study Drug</Form.Label>
        <Typeahead
          id={id}
          onChange={handler}
          onBlur={this.blurHandler}
          options={drugOptions || []}
          selected={value ? [value] : []}
          isValid={this.isStudyDrugValid(value)}
        />
      </Col>
    );
  };

  cancerTypeInput = (state) => {
    const { cancerTypeOptions } = this.state;
    return this.selectField(
      "Cancer Type",
      "cancerType",
      state,
      true,
      cancerTypeOptions,
      this.handleChange
    );
  };

  cancerSubTypeInput = (state, cancerState, handler) => {
    const { cancerTypeMap } = this.state;
    const options =
      cancerTypeMap && cancerState ? cancerTypeMap[cancerState] : [];
    return options && options.length > 0
      ? this.selectField(
          "Cancer Sub-Type",
          "cancerSubType",
          state,
          true,
          options,
          handler
        )
      : null;
  };

  otherCancerSubTypeInput = (state, cancerSubTypeState, handler) =>
    this.otherInput(
      "Other Cancer Sub-Type",
      "otherCancerSubType",
      state,
      cancerSubTypeState,
      handler || this.handleChange
    );

  otherCancerStageInput = (state, cancerStageState, handler) =>
    this.otherInput(
      "Other Cancer Stage",
      "otherStage",
      state,
      cancerStageState,
      handler || this.handleChange
    );

  otherTreatmentTimingInput = (state, treatmentTimingState, handler) =>
    this.otherInput(
      "Other Treatment Timing",
      "otherTreatmentTiming",
      state,
      treatmentTimingState,
      handler || this.handleChange
    );

  otherConcurrentSocInput = (state, concurrentSocState, handler) =>
    this.otherInput(
      "Other Concurrent SOC",
      "otherSoc",
      state,
      concurrentSocState,
      handler || this.handleChange
    );

  histologyInput = (state, handler) =>
    this.inputField("Histology", "histology", state, true, handler);

  molecularMarkerInput = (state, handler) =>
    this.inputField(
      "Molecular Marker",
      "molecularMarker",
      state,
      true,
      handler || this.handleChange
    );

  outcomesInput = (state, handler) =>
    this.textArea("Outcomes", "outcomes", state, handler);

  cancerStageInput = (state, handler) => {
    const { cancerStageOptions } = this.state;
    return this.selectField(
      "Cancer Stage",
      "cancerStage",
      state,
      true,
      cancerStageOptions.map((x) => [x.name, x.label]),
      handler || this.handleChange
    );
  };

  treatmentTimingInput = (state, options, handler) =>
    this.selectField(
      "Treatment Timing",
      "treatmentTiming",
      state,
      true,
      options,
      handler || this.handleChange
    );

  drugClassInput = (state, handler) => {
    const { drugClassOptions } = this.state;
    return this.selectField(
      "Drug Category",
      "drugClass",
      state,
      true,
      drugClassOptions,
      handler || this.handleChange
    );
  };

  otherDrugInput = (state, drugState, handler) =>
    this.otherInput("Other Drug", "otherStudyDrug", state, drugState, handler);

  otherDrugClassInput = (state, drugClassState, handler) =>
    this.otherInput(
      "Other Drug Category",
      "otherDrugClass",
      state,
      drugClassState,
      handler || this.handleChange
    );

  concurrentSocInput = (state, handler) => {
    const { concurrentSocOptions } = this.state;
    return this.selectField(
      "Concurrent SOC",
      "concurrentSoc",
      state,
      true,
      concurrentSocOptions,
      handler || this.handleChange
    );
  };

  dosageInput = (state, handler) =>
    this.inputField("Dosage", "dosage", state, true, "text", handler);

  numberOfPatientsInput = (state, handler) =>
    this.inputField(
      "Number of Patients",
      "numberOfPatients",
      state,
      true,
      "number",
      handler || this.handleChange
    );

  // SECTION observational fields
  observationalSubTypeInput = (state, handler) => {
    const { observationalTypeOptions } = this.state;
    return this.selectField(
      "Study Sub-Type",
      "studySubType",
      state,
      true,
      observationalTypeOptions,
      handler || this.handleChange
    );
  };

  otherObservationalSubTypeInput = (state, subTypeState, handler) =>
    this.otherInput(
      "Other Study Sub-Type",
      "otherStudySubType",
      state,
      subTypeState,
      handler || this.handleChange
    );

  studyPopulationSexInput = (state, handler) => {
    const { studyPopulationSexOptions } = this.state;
    return this.selectField(
      "Study Population Sex",
      "studyPopulationSex",
      state,
      true,
      studyPopulationSexOptions,
      handler || this.handleChange
    );
  };

  drugUseDurationInput = (state, handler) =>
    this.inputField(
      "Drug Use Duration",
      "drugUseDuration",
      state,
      true,
      "text",
      handler || this.handleChange
    );

  studyStartDateInput = (state, handler) =>
    dateField(
      "Start Date",
      "studyStartDate",
      state,
      handler || this.handleChange
    );

  studyEndDateInput = (state, handler) =>
    dateField("End Date", "studyEndDate", state, handler || this.handleChange);

  userBiasInput = (state, handler) => {
    const { userBiasOptions } = this.state;
    return this.selectField(
      "User Bias",
      "userBias",
      state,
      true,
      userBiasOptions,
      handler || this.handleChange
    );
  };

  immortalTimeBiasInput = (state, handler) =>
    this.yesNoField(
      "Risk of Immortal-Time Bias",
      "immortalTimeBiasRisk",
      state,
      true,
      handler || this.handleChange
    );

  riskPrevalentBiasInput = (state, handler) =>
    this.yesNoField(
      "Risk of Prevalent-User/Selection Bias",
      "selectionBiasRisk",
      state,
      true,
      handler || this.handleChange
    );

  confoundingIndicationInput = (state, handler) =>
    this.yesNoField(
      "Potential for confounding by indication?",
      "confoundingByIndication",
      state,
      true,
      handler || this.handleChange
    );

  confoundersInput = (state, handler) => {
    const { confounderOptions } = this.state;
    return checkboxGroupInput(
      "Confounders",
      "confounders",
      state,
      handler,
      confounderOptions
    );
  };

  qualityConcernInput = (state, handler) =>
    this.yesNoField(
      "Did you have any other concerns about study quality?",
      "qualityConcern",
      state,
      true,
      handler || this.handleChange
    );

  qualityNotesInput = (state, handler) =>
    this.textArea("Quality Notes", "qualityNotes", state, handler);

  yesNoField = (label, name, value, enabled, changeHandler) =>
    yesNoField(
      label,
      name,
      value,
      enabled,
      changeHandler || this.handleYesNoChange
    );

  otherInput = (label, name, state, relatedState, handler) =>
    otherInput(label, name, state, relatedState, handler || this.handleChange);

  inputField = (label, name, value, disabled, inputType, changeHandler) =>
    inputField(
      label,
      name,
      value,
      disabled,
      inputType,
      changeHandler || this.handleChange
    );

  inputFieldRequired = (
    label,
    name,
    value,
    disabled,
    inputType,
    changeHandler
  ) =>
    inputFieldRequired(
      label,
      name,
      value,
      disabled,
      inputType,
      changeHandler || this.handleChange
    );

  textArea = (label, name, value, changeHandler) =>
    textArea(label, name, value, changeHandler || this.handleChange);

  selectField = (label, name, value, disabled, options, changeHandler) =>
    selectField(
      label,
      name,
      value,
      disabled,
      options,
      changeHandler || this.handleChange
    );

  render() {
    const {
      articleRequestInProgress,
      index,
      formType,
      newcastleSurvey,
      studySubType,
    } = this.state;

    if (articleRequestInProgress === true) {
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="loadingIndicator"
          size="8x"
        />
      );
    }

    return (
      <>
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/new-article" }}
            active
          >
            Add/Edit
          </Breadcrumb.Item>
        </Breadcrumb>

        <Container>
          {/* once user clicks formType button, display this chunk */}
          <>
            <h1>{formType === "add" ? "Add New" : "Edit Existing"} Article</h1>
            {this.renderStatusFlow()}
            <Form
              className="article-form"
              name="article-form"
              onSubmit={this.handleSubmit}
            >
              {this.articleTypeSelector()}
              {this.pubmedGroup()}
              <NewcastleSurvey
                surveyResponseId={newcastleSurvey ? newcastleSurvey.id : null}
                surveyResponse={newcastleSurvey}
                surveyHandler={this.surveyHandler}
                articleType={studySubType}
              />
              {formType === "add" && (
                <Button
                  onClick={() => {
                    this.postArticle();
                  }}
                  disabled={!index}
                >
                  Add
                </Button>
              )}
              {formType === "edit" && (
                <Button
                  onClick={() => {
                    this.patchArticle();
                  }}
                >
                  Save
                </Button>
              )}
            </Form>
          </>
        </Container>
      </>
    );
  }
}

NewArticle.propTypes = {
  articleId: PropTypes.string,
  formType: PropTypes.string,
  index: PropTypes.string,
};

NewArticle.defaultProps = {
  articleId: undefined,
  formType: undefined,
  index: undefined,
};

export default NewArticle;
