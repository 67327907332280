import PropTypes from "prop-types";
import { Component } from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import { observationalResultOutcomePropType } from "../../../models/propTypes";
import { inputField } from "../../basic-input";
import VariableOutcome from "./VariableOutcome";

const varNameInput = (state, handler) =>
  inputField("Variable Name", "varName", state, true, "text", handler);

class Variable extends Component {
  constructor(props, context) {
    super(props, context);
    const { data } = props;
    this.state = {
      varName: data ? data.varName : null,
      outcomes: data ? data.outcomes : [],
    };
  }

  render = () => {
    const { varName, outcomes } = this.state;
    const { outcomeTypes, statisticRatio } = this.props;
    return (
      <InputGroup style={{ border: "1px solid black", margin: "1em 1em" }}>
        {varName !== "univariate" && (
          <Row>
            {varNameInput(varName, this.handleChange)}
            <Col>
              <Button onClick={this.removeVariable}>Remove Variable</Button>
            </Col>
          </Row>
        )}
        {outcomeTypes.map((outcomeType, i) => (
          <Row key={outcomeType}>
            <VariableOutcome
              onChange={this.handleOutcomeChange(i)}
              outcomeType={outcomeType}
              data={outcomes.find((y) => y.type === outcomeType)}
              statisticRatio={statisticRatio}
            />
          </Row>
        ))}
      </InputGroup>
    );
  };

  handleOutcomeChange = (index) => (outcomeState) => {
    const { outcomes: previousOutcomes } = this.state;
    const outcomes = [...previousOutcomes];
    outcomes[index] = outcomeState;
    this.setState({ outcomes }, this.runOnChange);
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.runOnChange
    );
  };

  runOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.state);
    }
  };

  removeVariable = () => {
    const { onRemove } = this.props;
    if (onRemove) {
      onRemove();
    }
  };
}

Variable.propTypes = {
  outcomeTypes: PropTypes.arrayOf(PropTypes.string),
  statisticRatio: PropTypes.string,
  data: observationalResultOutcomePropType,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

Variable.defaultProps = {
  outcomeTypes: [],
  statisticRatio: undefined,
  data: undefined,
  onChange: undefined,
  onRemove: undefined,
};

export default Variable;
