import { FC, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import NewArticle from "../NewArticle";

type PathParams = {
  id: string;
};

export type EditClinicalTrialProps = RouteComponentProps<PathParams>;

const EditClinicalTrial: FC<EditClinicalTrialProps> = (
  props: EditClinicalTrialProps
): ReactElement => {
  const { match } = props;
  return (
    <NewArticle
      formType="edit"
      index="clinical-trial"
      articleId={match?.params?.id}
      {...props}
    />
  );
};

export default EditClinicalTrial;
