import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  fetchIndexLabels,
  getMyWork,
  indexLabel,
} from "../../components/utilities/request";
import MorningsideIndex from "../../models/MorningsideIndex";
import "./styles.scss";

const shortenString = (string) => {
  const stringLength = 96;
  let shortString = string;
  if (string.length > stringLength) {
    shortString = string.slice(0, stringLength);
    if (
      shortString.slice(-1) === " " ||
      shortString.slice(-1) === "," ||
      shortString.slice(-1) === "," ||
      shortString.slice(-1) === ";"
    ) {
      shortString = shortString.slice(0, -10);
    }
  }
  return `${shortString}…`;
};

class MyWork extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showSummaryModal: false,
      modalText: null,
      indexLabels: [],
      requestInProgress: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.buildRows = this.buildRows.bind(this);
  }

  async componentDidMount() {
    fetchIndexLabels().then((response) =>
      this.setState({ indexLabels: response.data })
    );

    const myWork = await getMyWork();
    if (myWork.status === 200) {
      this.setState({
        requestInProgress: false,
      });
      if (myWork.data !== null) {
        this.setState({
          workObj: myWork.data,
        });
      }
    } else {
      this.setState({
        requestInProgress: false,
      });
    }
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  indexLabel(key) {
    const { indexLabels } = this.state;
    return indexLabel(indexLabels, key);
  }

  buildRows(studies, type) {
    const { features } = this.props;
    const { requestInProgress } = this.state;

    if (requestInProgress === true) {
      return (
        <tr>
          <td colSpan={5}>Loading...</td>
        </tr>
      );
    }
    if (studies.content.length === 0) {
      return (
        <tr>
          <td colSpan={5}>Nothing to show.</td>
        </tr>
      );
    }

    return studies.content.map((study) => {
      return (
        <tr key={study.id}>
          <td>
            <Link
              to={{
                pathname: `/${type}/${study.content.sfId}`,
                state: {
                  prevPath: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              {study.content.publication.pmid || "View "}
            </Link>
            <Link
              to={{
                pathname: `/${type}/${study.content.sfId}`,
                state: {
                  prevPath: `${window.location.pathname}${window.location.search}`,
                },
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
            <br />
            {!features.includes("Remedy Article Publishing") &&
            (study.content.status === "In Review" ||
              study.content.status === "Published") ? null : (
              <Link
                to={{
                  pathname: `/edit-article/${type}/${study.content.sfId}`,
                  state: {
                    prevPath: `${window.location.pathname}${window.location.search}`,
                  },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit
              </Link>
            )}
          </td>
          {features.includes("Remedy Article Editing") ? (
            <td>{study.content.status}</td>
          ) : null}
          <td>{study.content.cancerType}</td>
          <td>{study.content.studyDrug}</td>
          <td>
            {study.content.morningsideSummary
              ? shortenString(study.content.morningsideSummary)
              : null}{" "}
            {study.content.morningsideSummary ? (
              <Button
                as="a"
                bsPrefix="text-btn"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.setState(
                    { modalText: study.content.morningsideSummary },
                    () => this.setState({ showSummaryModal: true })
                  )
                }
              >
                Show More
              </Button>
            ) : null}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { features } = this.props;
    const { modalText, showSummaryModal, workObj } = this.state;

    return (
      <div className="my-work">
        <Modal
          size="lg"
          show={showSummaryModal}
          onHide={() => this.setState({ showSummaryModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h2>Morningside Summary</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalText}</Modal.Body>
        </Modal>
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/my-work" }} active>
            My Work
          </Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <h1>My Work</h1>
          <Row>
            <Col>
              <Tabs id="my-work__tabs">
                {_.values(MorningsideIndex).map((morningsideIndex) => (
                  <Tab
                    key={morningsideIndex}
                    eventKey={morningsideIndex}
                    title={this.indexLabel(morningsideIndex)}
                  >
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>PMID</th>
                          {features.includes("Remedy Article Editing") ? (
                            <th>Status</th>
                          ) : null}
                          <th>Cancer Type</th>
                          <th>Study Drug</th>
                          <th>Morningside Summary</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {requestInProgress ? (
                          <Loading />
                        ) : (
                          <> */}
                        {workObj
                          ? this.buildRows(
                              workObj[morningsideIndex],
                              morningsideIndex
                            )
                          : null}
                        {/* </>
                        )} */}
                      </tbody>
                    </Table>
                  </Tab>
                ))}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

MyWork.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
};

MyWork.defaultProps = {
  features: [],
};

export default MyWork;
