import PropTypes from "prop-types";
import { Component } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { stratifiedOutcomePropType } from "../../../models/propTypes";
import { booleanCheckboxInput, inputField } from "../../basic-input";
import { fetchObservationalOutcomeTypes } from "../../utilities/request";

const upperCiInput = (state, handler) =>
  inputField("Upper CI", "upperCI", state, true, "number", handler);

const lowerCiInput = (state, handler) =>
  inputField("Lower CI", "lowerCI", state, true, "number", handler);

const totalInput = (state, handler) =>
  inputField("Total", "total", state, true, "number", handler);

const deathsInput = (state, handler) =>
  inputField("Deaths", "deaths", state, true, "number", handler);

const primaryInput = (state, handler) =>
  booleanCheckboxInput("Primary", "primary", state, handler, ["Yes", "No"]);

class VariableOutcome extends Component {
  constructor(props, context) {
    super(props, context);
    const { outcomeType, data } = props;
    this.state = {
      type: outcomeType,
      hr: data ? data.hr : null,
      upperCI: data ? data.upperCI : null,
      lowerCI: data ? data.lowerCI : null,
      total: data ? data.total : null,
      deaths: data ? data.deaths : null,
      primary: data ? data.primary : false,
      outcomeTypeOptions: null,
    };
  }

  async componentDidMount() {
    fetchObservationalOutcomeTypes().then((response) =>
      this.setState({
        outcomeTypeOptions: response.data,
      })
    );
  }

  render = () => {
    const { outcomeType } = this.props;
    const { hr, upperCI, lowerCI, total, deaths, primary } = this.state;
    return (
      <InputGroup
        style={{
          borderWidth: "1px 0px 0px 0px",
          borderStyle: "solid",
          borderColor: "black",
          margin: "0em",
        }}
      >
        <Row>
          <Col>
            <Row>
              <Form.Label>Type</Form.Label>
            </Row>
            <Row>{outcomeType}</Row>
          </Col>
          {this.hrInput(hr, this.handleChange)}
          {upperCiInput(upperCI, this.handleChange)}
          {lowerCiInput(lowerCI, this.handleChange)}
          {totalInput(total, this.handleChange)}
          {deathsInput(deaths, this.handleChange)}
          {primaryInput(primary, this.handleCheckChange)}
        </Row>
      </InputGroup>
    );
  };

  hrInput = (state, handler) => {
    const { statisticRatio } = this.props;
    return inputField(
      statisticRatio || "HR",
      "hr",
      state,
      true,
      "number",
      handler
    );
  };

  handleCheckChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.checked,
      },
      () => {
        const { onChange } = this.props;
        if (onChange) {
          const state = { ...this.state };
          delete state.outcomeTypeOptions;
          onChange(state);
        }
      }
    );
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const { onChange } = this.props;
        if (onChange) {
          const state = { ...this.state };
          delete state.outcomeTypeOptions;
          onChange(state);
        }
      }
    );
  };
}

VariableOutcome.propTypes = {
  outcomeType: PropTypes.string,
  statisticRatio: PropTypes.string,
  data: stratifiedOutcomePropType,
  onChange: PropTypes.func,
};

VariableOutcome.defaultProps = {
  outcomeType: undefined,
  statisticRatio: undefined,
  data: undefined,
  onChange: undefined,
};

export default VariableOutcome;
