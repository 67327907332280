import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import "../styles/statusFlow.scss";
import { patchArticleStatus } from "./utilities/request";

class StatusFlow extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      requestInProgress: false,
      currentStatus: props.currentStatus,
    };
  }

  handleSpinner(status) {
    const { requestInProgress } = this.state;
    if (requestInProgress === status) {
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="loadingIndicator"
          style={{ color: "white", margin: "0px" }}
        />
      );
    }
    return status;
  }

  async changeStatus(newStatus) {
    const { sfid } = this.props;
    this.setState({ requestInProgress: newStatus });
    patchArticleStatus(sfid, newStatus, () => {
      this.setState({ requestInProgress: false, currentStatus: newStatus });
    });
  }

  render() {
    const { currentStatus: currentStatusProp } = this.props;
    const { currentStatus } = this.state;
    const userFeatures = sessionStorage.getItem(`my_features`);
    const current = currentStatus || currentStatusProp;

    let buttons = [
      {
        status: "In Progress",
        disabled: true,
        current: false,
      },
      {
        status: "Submitted",
        disabled: true,
        current: false,
      },
      {
        status: "In Review",
        disabled: true,
        current: false,
      },
      {
        status: "Published",
        disabled: true,
        current: false,
      },
    ];

    // disable buttons based on user's features
    // !!!
    // if a user has Publishing permissions, we DO
    // NOT ASSUME they also have Editing permissions
    // !!!
    buttons = buttons.map((oldButton) => {
      const button = { ...oldButton };
      // users who can Edit are allowed to click the first two statuses
      if (
        userFeatures.includes("Remedy Article Editing") &&
        (button.status === "In Progress" || button.status === "Submitted")
      ) {
        button.disabled = false;
      }
      // users who can Publish are allowed to click the second two statuses
      if (
        userFeatures.includes("Remedy Article Publishing") &&
        (button.status === "In Review" || button.status === "Published")
      ) {
        button.disabled = false;
      }
      // users who cannot Publish are not allowed to click the first two statuses if the article has moved on to the second two
      if (
        !userFeatures.includes("Remedy Article Publishing") &&
        (current === "In Review" || current === "Published")
      ) {
        button.disabled = true;
      }
      return button;
    });

    // identify/disable button of article's current Status
    // (.every exits when returns false)
    buttons = buttons.map((oldButton) => {
      const button = { ...oldButton };
      if (button.status === current) {
        button.disabled = true;
        button.current = true;
        button.stage = "current";
      }
      return button;
    });

    return (
      <ButtonGroup id="status-flow">
        {buttons.map((button) => {
          return (
            <Button
              onClick={() => this.changeStatus(button.status)}
              key={button.status}
              disabled={button.disabled}
              id={button.current ? "current" : null}
            >
              <span>{this.handleSpinner(button.status)}</span>
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}

StatusFlow.propTypes = {
  currentStatus: PropTypes.string,
  sfid: PropTypes.string,
};

StatusFlow.defaultProps = {
  currentStatus: undefined,
  sfid: undefined,
};

export default StatusFlow;
