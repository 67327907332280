/* eslint-disable react/no-danger */
import { faDizzy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import React, { Component } from "react";
import {
  Accordion,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import { Link, Route } from "react-router-dom";

import Comments from "../../components/commentBox";
import OutcomesDisplay from "../../components/outcomesDisplay";
import Definitions from "../../components/utilities/definitions";
import {
  fetchCancerStagesV2,
  fetchIndexLabels,
  getClinicalTrial,
} from "../../components/utilities/request";
import CustomToggle from "./components/CustomToggle";

const detailsGroup = (title, body) => {
  let bodyString;
  if (body === null) {
    bodyString = <span className="missing-info">n/a</span>;
  } else {
    bodyString = body;
  }
  return (
    <>
      <h4>{title}</h4>
      <p>{bodyString}</p>
    </>
  );
};

class Article extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
      article: null,
      showModal: false,
    };
  }

  async componentDidMount() {
    const indexLabels = await fetchIndexLabels();
    const cancerStageOptions = await fetchCancerStagesV2();
    const thisArticle = await getClinicalTrial(window.location.pathname);
    if (indexLabels.status === 200 && thisArticle.status === 200) {
      this.setState({
        loaded: true,
        article: thisArticle.data,
        indexLabels: indexLabels.data,
        cancerStageOptions: cancerStageOptions.data,
      });
    } else {
      this.setState({
        loaded: true,
        article: false,
      });
    }
  }

  lookupCancerStageLabel(name) {
    const { cancerStageOptions } = this.state;
    return cancerStageOptions
      .filter((x) => x.name === name)
      .map((x) => x.label)[0];
  }

  render() {
    const { location } = this.props;
    const { article, indexLabels, loaded, showModal } = this.state;

    if (loaded === false) {
      return (
        <>
          <Breadcrumb id="breadcrumb-bar">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            {location.state ? (
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: `${location.state.prevPath}` }}
              >
                Search
              </Breadcrumb.Item>
            ) : null}
          </Breadcrumb>
        </>
      );
    }

    if (article === false) {
      return (
        <>
          <Breadcrumb id="breadcrumb-bar">
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              Home
            </Breadcrumb.Item>
            {location.state ? (
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: `${location.state.prevPath}` }}
              >
                Search
              </Breadcrumb.Item>
            ) : null}
          </Breadcrumb>
          <h1 id="error-msg">
            4<FontAwesomeIcon icon={faDizzy} />4 Not Found
          </h1>
        </>
      );
    }

    return (
      <>
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          {location.state ? (
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: `${location.state.prevPath}` }}
            >
              Search
            </Breadcrumb.Item>
          ) : null}
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "#" }} active>
            {article.publication.pmid}
          </Breadcrumb.Item>
        </Breadcrumb>

        <Modal
          size="lg"
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h2>Glossary</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Definitions />
          </Modal.Body>
        </Modal>

        <Container id="article-page">
          <h1>{article.publication.articleTitle}</h1>
          <Row>
            <Col id="article-details">
              {detailsGroup("Authors", article.publication.authors)}
              <Accordion id="affiliationsAccordion">
                <Card>
                  <Card.Header>
                    Affiliations <CustomToggle eventKey="0" text="+ expand" />
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <>
                        {article.publication.authorsWithAffiliations &&
                        article.publication.authorsWithAffiliations !== null &&
                        article.publication.authorsWithAffiliations.length >
                          0 ? (
                          article.publication.authorsWithAffiliations.map(
                            (author) => {
                              const authorName = `${author.lastName}, ${author.firstName} ${author.middleName}`;
                              return (
                                <p key={authorName}>
                                  {`${authorName} - ${author.affiliations.join(
                                    " "
                                  )}`}
                                </p>
                              );
                            }
                          )
                        ) : (
                          <span className="missing-info">n/a</span>
                        )}
                      </>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              {detailsGroup("Journal Title", article.publication.journalTitle)}
              {detailsGroup("PMID", article.publication.pmid)}
              {detailsGroup("Year", article.publication.publicationYear)}
              <h4>DOI</h4>
              <p>
                {article.publication.doi ? (
                  <a href={`https://www.doi.org/${article.publication.doi}`}>
                    {article.publication.doi}
                  </a>
                ) : (
                  <span className="missing-info">n/a</span>
                )}
              </p>
              <Accordion defaultActiveKey="1">
                <Card as={Accordion.Item} eventKey="1">
                  <Accordion.Button as={Card.Header}>
                    Morningside Center Summary
                  </Accordion.Button>
                  <Accordion.Body as={Card.Body}>
                    <p>
                      {article.morningsideSummary ? (
                        <span
                          style={{ color: "black", padding: "2em" }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              article.morningsideSummary
                            ),
                          }}
                        />
                      ) : (
                        <span className="missing-info">n/a</span>
                      )}
                    </p>
                  </Accordion.Body>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="2">
                <Card as={Accordion.Item} eventKey="2">
                  <Accordion.Button as={Card.Header}>
                    PubMed Abstract
                  </Accordion.Button>
                  <Accordion.Body as={Card.Body}>
                    {article.publication.abstractTexts.length > 0 ? (
                      article.publication.abstractTexts.map((abstract) => {
                        return (
                          <React.Fragment key={abstract.text}>
                            <h4>{abstract.label}</h4>
                            <p>{abstract.text}</p>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <span className="missing-info">n/a</span>
                    )}
                  </Accordion.Body>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="3">
                <Card as={Accordion.Item} eventKey="3">
                  <Accordion.Button as={Card.Header}>
                    Study Info
                  </Accordion.Button>
                  <Accordion.Body as={Card.Body}>
                    {detailsGroup(
                      "Study Type",
                      indexLabels.find(
                        (element) => element.name === article.index
                      ).label
                    )}
                    <h4>Study Subtype</h4>
                    <p>
                      {article.studySubTypes &&
                      article.studySubTypes.length > 0 ? (
                        article.studySubTypes.join("; ")
                      ) : (
                        <span className="missing-info">n/a</span>
                      )}
                    </p>
                    {detailsGroup(
                      "Number of Patients",
                      article.numberOfPatients
                    )}
                    <Accordion className="definitions-accordion">
                      <Card as={Accordion.Item} eventKey="0">
                        <Accordion.Button as={Card.Header}>
                          <Button>Definitions</Button>
                        </Accordion.Button>
                        <Accordion.Body as={Card.Body}>
                          <p>
                            <b>Study Type:</b> Classification of research type
                          </p>
                          <p>
                            <b>Study Subtype:</b> Specific description of study
                            type
                          </p>
                          <p>
                            <b>Clinical Trials:</b> Prospective studies that
                            evaluate an intervention, such as a drug, for safety
                            and efficacy in people.
                          </p>
                        </Accordion.Body>
                      </Card>
                    </Accordion>
                  </Accordion.Body>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="4">
                <Card as={Accordion.Item} eventKey="4">
                  <Accordion.Button as={Card.Header}>Disease</Accordion.Button>
                  <Accordion.Body as={Card.Body}>
                    {detailsGroup("Cancer Type", article.cancerType)}
                    {detailsGroup("Cancer Subtype", article.cancerSubType)}
                    {detailsGroup(
                      "Cancer Stage",
                      article.cancerStage
                        ?.split(";")
                        .map((x) => this.lookupCancerStageLabel(x))
                        .join("; ")
                    )}
                    <h4>TNM Staging</h4>
                    <p>
                      {article.tnmStages ? (
                        article.tnmStages.join(", ")
                      ) : (
                        <span className="missing-info">n/a</span>
                      )}
                    </p>
                    {detailsGroup("Histology", article.histology)}
                    {detailsGroup("Molecular Marker", article.molecularMarker)}
                    <Accordion className="definitions-accordion">
                      <Card as={Accordion.Item} eventKey="0">
                        <Accordion.Button as={Card.Header}>
                          <Button>Definitions</Button>
                        </Accordion.Button>
                        <Accordion.Body as={Card.Body}>
                          <p>
                            <b>Cancer Type:</b> Classification of cancer
                            typically based upon the organs or tissue where the
                            cancer originated.
                          </p>
                          <p>
                            <b>Cancer Subtype:</b> Describes the smaller groups
                            that a type of cancer can be divided into, based on
                            certain characteristics of the cancer cells. These
                            characteristics include how the cancer cells look
                            under a microscope and whether there are certain
                            substances in or on the cells or certain changes to
                            the DNA of the cells.
                          </p>
                          <p>
                            <b>Cancer Stage:</b> Classification of cancer based
                            upon the advancement of the disease.
                          </p>
                          <p>
                            <b>Histology:</b> Classification of cancer based
                            upon tissue.
                          </p>
                          <p>
                            <b>Molecular Marker:</b> Classification of cancer
                            based upon a biological marker.
                          </p>
                        </Accordion.Body>
                      </Card>
                    </Accordion>
                  </Accordion.Body>
                </Card>
              </Accordion>
              <Accordion defaultActiveKey="5">
                <Card as={Accordion.Item} eventKey="5">
                  <Accordion.Button as={Card.Header}>
                    Treatment
                  </Accordion.Button>
                  <Accordion.Body as={Card.Body}>
                    {detailsGroup("Study Drug", article.studyDrug)}
                    {detailsGroup("Drug Category", article.drugClass)}
                    {detailsGroup("Dosage", article.dosage)}
                    {detailsGroup("Treatment Timing", article.treatmentTiming)}
                    {detailsGroup("Concurrent SOC", article.concurrentSoc)}
                    <>
                      <h4>Outcomes : </h4>
                      <OutcomesDisplay data={article} />
                    </>
                    <Accordion className="definitions-accordion">
                      <Card as={Accordion.Item} eventKey="0">
                        <Accordion.Button as={Card.Header}>
                          <Button>Definitions</Button>
                        </Accordion.Button>
                        <Accordion.Body as={Card.Body}>
                          <p>
                            <b>Study Drug:</b> Treatment being studied in a
                            trial.
                          </p>
                          <p>
                            <b>Treatment Timing:</b> Period of time that the
                            treatment of interest was received.
                          </p>
                        </Accordion.Body>
                      </Card>
                    </Accordion>
                  </Accordion.Body>
                </Card>
              </Accordion>
              <Comments articlePath={location.pathname} />
            </Col>
            <Col md={2} className="article-tools">
              <Button className="btn" onClick={window.print}>
                Print
              </Button>
              <a
                href={`${article.publication.pmidUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                disabled={!article.publication.pmidUrl}
              >
                <Button>PubMed</Button>
              </a>
              <a
                href={`${article.ctGovUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                disabled={!article.ctGovUrl}
              >
                <Button>Clinicaltrials.gov</Button>
              </a>
              <Button
                className="btn"
                onClick={() => {
                  this.setState({ showModal: true });
                }}
              >
                Glossary
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Article.propTypes = Route.propTypes;

export default Article;
