import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { promiseTrackerHoc, usePromiseTracker } from "react-promise-tracker";

export const SpinnerInner = () => {
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    if (promiseInProgress) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style = {
        ...document.body.style,
        overflow: undefined,
      };
    }
  }, [promiseInProgress]);

  return (
    <div>
      {promiseInProgress === true ? (
        <div id="main-spinner-container">
          <FontAwesomeIcon icon={faCircleNotch} className="fas fa-spin fa-8x" />
        </div>
      ) : null}
    </div>
  );
};

export const LoadingSpinner = promiseTrackerHoc(SpinnerInner);
