import { FC, ReactElement } from "react";
import FeatureName from "../../../../../../models/FeatureName";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../../../../../../modules/shared/src/components/ConfirmationModal";
import ManagedContent from "../../../../../../modules/shared/src/components/ManagedContent";
import ManagedContentName from "../../../../models/ManagedContentName";
import "./styles.scss";

export type LegalDisclaimerModalProps = Pick<
  ConfirmationModalProps,
  "onCancel" | "onConfirm"
>;

const LegalDisclaimerModal: FC<LegalDisclaimerModalProps> = (
  props
): ReactElement => {
  return (
    <ConfirmationModal
      show
      hideCancelButton
      id="legal-disclaimer-modal"
      title={
        <ManagedContent
          name={ManagedContentName.LegalDisclaimerModalTitle}
          featureName={FeatureName.Home}
        />
      }
      body={
        <ManagedContent
          name={ManagedContentName.LegalDisclaimerModalBody}
          featureName={FeatureName.Home}
        />
      }
      {...props}
    />
  );
};

export default LegalDisclaimerModal;
