/* eslint-disable react/no-danger */
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { displayValuePropType, searchHitPropType } from "../models/propTypes";
import { shortString } from "../utils/searchTableUtils";
import OutcomesDisplay from "./outcomesDisplay";
import UnpublishedIndicator from "./utilities/unpublishedIndicator";

class SearchCaseReportTable extends Component {
  closeButtonClickHandler = () => {
    const { callbackModal, data } = this.props;
    callbackModal(data.content.morningsideSummary);
  };

  render() {
    const featuresList = sessionStorage.getItem(`my_features`);
    const { cancerStageMapper, cancerStages, data, type } = this.props;

    if (data.id) {
      return (
        <tr key={data.id}>
          <td>
            <Link
              to={{
                pathname: `/${data.index}/${data.id}`,
                state: {
                  prevPath: `${window.location.pathname}${window.location.search}`,
                },
              }}
            >
              {data.content.publication.pmid || "View "}
            </Link>
            <Link
              to={{
                pathname: `/${data.index}/${data.id}`,
                state: {
                  prevPath: `${window.location.pathname}${window.location.search}`,
                },
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Link>
            {featuresList.includes("Remedy Article Editing") ? (
              <>
                <br />
                <Link
                  to={{
                    pathname: `/edit-article/${data.index}/${data.id}`,
                    state: {
                      prevPath: `${window.location.pathname}${window.location.search}`,
                    },
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit
                </Link>
              </>
            ) : null}
            {data.content.status !== "Published" &&
            (featuresList.includes("Remedy Article Editing") ||
              featuresList.includes("Remedy Article Publishing")) ? (
              <>
                {" "}
                <UnpublishedIndicator />
              </>
            ) : null}
            <hr />
            {data.content.publication.publicationYear}
          </td>
          <td>
            {type.label}
            {data.content.studySubTypes.length > 0 ? <hr /> : null}
            {shortString(data.content.studySubTypes.join(", "))}
          </td>
          <td>
            {data.content.cancerStage ? (
              <>
                {shortString(
                  cancerStageMapper(cancerStages, data.content.cancerStage)
                )}
              </>
            ) : (
              `n/a`
            )}
            <hr />
            {data.content.cancerType ? (
              <>
                {shortString(data.content.cancerType)}
                <br />
              </>
            ) : (
              `n/a`
            )}
            <hr />
            {data.content.cancerSubType ? (
              <>
                {shortString(data.content.cancerSubType)}
                <br />
              </>
            ) : (
              `n/a`
            )}
          </td>
          <td>
            {data.content.studyDrug
              ? shortString(data.content.studyDrug)
              : "n/a"}
            <hr />
            {data.content.drugClasses.length > 0
              ? shortString(data.content.drugClasses.join(", "))
              : "n/a"}
          </td>
          <td>{shortString(data.content.treatmentTiming)}</td>
          <td>{shortString(data.content.dosage)}</td>
          <td>{shortString(data.content.concurrentSoc)}</td>
          <td>{shortString(data.content.numberOfPatients)}</td>

          <td>
            <OutcomesDisplay data={data.content} />
          </td>
          <td>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  shortString(data.content.morningsideSummary)
                ),
              }}
            />{" "}
            {data.content.morningsideSummary ? (
              <Button
                as="a"
                bsPrefix="text-btn"
                onClick={() => {
                  this.closeButtonClickHandler();
                }}
              >
                Read More
              </Button>
            ) : null}
          </td>
        </tr>
      );
    }

    return undefined;
  }
}

SearchCaseReportTable.propTypes = {
  callbackModal: PropTypes.func,
  data: searchHitPropType,
  type: displayValuePropType,
  cancerStageMapper: PropTypes.func,
  cancerStages: PropTypes.arrayOf(displayValuePropType),
};

SearchCaseReportTable.defaultProps = {
  callbackModal: () => {},
  data: undefined,
  type: undefined,
  cancerStageMapper: () => {},
  cancerStages: [],
};

export default SearchCaseReportTable;
