/* istanbul ignore file */
import { useCallback } from "react";
import useMountedState from "../useMountedState";

// https://www.benmvp.com/blog/handling-async-react-component-effects-after-unmount/
const useSafeAsync = <T = unknown>(): ((
  promise: Promise<T>
) => Promise<T | undefined>) => {
  const isMounted = useMountedState();

  return useCallback(
    (promise: Promise<T>) =>
      new Promise((resolve, reject) => {
        if (promise) {
          promise
            .then((value) => {
              if (isMounted()) {
                resolve(value);
              }
            })
            .catch((error) => {
              if (isMounted()) {
                reject(error);
              }
            });
        } else if (isMounted()) {
          resolve(undefined);
        }
      }),
    [isMounted]
  );
};

export default useSafeAsync;
