/* eslint-disable react/no-danger */
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { displayValuePropType, searchHitPropType } from "../models/propTypes";
import { shortString } from "../utils/searchTableUtils";
import PrimaryIndicator from "./utilities/primaryIndicator";
import UnpublishedIndicator from "./utilities/unpublishedIndicator";

class SearchClinicalTrialTable extends Component {
  closeButtonClickHandler = () => {
    const { callbackModal, data } = this.props;
    callbackModal(data.content.morningsideSummary);
  };

  render() {
    const featuresList = sessionStorage.getItem(`my_features`);
    const { cancerStageMapper, cancerStages, data, subtypes, type } =
      this.props;

    if (data.id) {
      return (
        <>
          <tr key={data.id}>
            <td rowSpan={data.content.outcomes.length || 1}>
              <Link
                to={{
                  pathname: `/${data.index}/${data.id}`,
                  state: {
                    prevPath: `${window.location.pathname}${window.location.search}`,
                  },
                }}
              >
                {data.content.publication.pmid || "View "}
              </Link>
              <Link
                to={{
                  pathname: `/${data.index}/${data.id}`,
                  state: {
                    prevPath: `${window.location.pathname}${window.location.search}`,
                  },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Link>
              {featuresList.includes("Remedy Article Editing") ? (
                <>
                  <br />
                  <Link
                    to={{
                      pathname: `/edit-article/${data.index}/${data.id}`,
                      state: {
                        prevPath: `${window.location.pathname}${window.location.search}`,
                      },
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Edit
                  </Link>
                </>
              ) : null}
              {data.content.status !== "Published" &&
              (featuresList.includes("Remedy Article Editing") ||
                featuresList.includes("Remedy Article Publishing")) ? (
                <>
                  {" "}
                  <UnpublishedIndicator />
                </>
              ) : null}
              <hr />
              {data.content.publication.publicationYear}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {type.label}
              {data.content.studySubTypes.length > 0 ? <hr /> : null}
              {shortString(
                data.content.studySubTypes
                  .map((it) => subtypes.find((x) => x.name === it))
                  .map((it) => it?.label ?? "???")
                  .join(", ")
              )}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {data.content.cancerStage ? (
                <>
                  {shortString(
                    cancerStageMapper(cancerStages, data.content.cancerStage)
                  )}
                </>
              ) : (
                `n/a`
              )}
              <hr />
              {data.content.cancerType ? (
                <>
                  {shortString(data.content.cancerType)}
                  <br />
                </>
              ) : (
                `n/a`
              )}
              <hr />
              {data.content.cancerSubType ? (
                <>
                  {shortString(data.content.cancerSubType)}
                  <br />
                </>
              ) : (
                `n/a`
              )}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {data.content.studyDrug
                ? shortString(data.content.studyDrug)
                : "n/a"}
              <hr />
              {data.content.drugClasses.length > 0
                ? shortString(data.content.drugClasses.join(", "))
                : "n/a"}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {shortString(data.content.treatmentTiming)}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {shortString(data.content.dosage)}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {shortString(data.content.concurrentSoc)}
            </td>
            <td rowSpan={data.content.outcomes.length || 1}>
              {shortString(data.content.numberOfPatients)}
            </td>

            {data.content.outcomes.length > 0 ? (
              <>
                <td className="search-results-table__striped_cell">
                  {data.content.outcomes[0].outcomeType}
                  {data.content.outcomes[0].primary ? (
                    <PrimaryIndicator />
                  ) : null}
                </td>
                <td className="search-results-table__striped_cell">
                  {data.content.outcomes[0].controlGroup}
                </td>
                <td className="search-results-table__striped_cell">
                  {data.content.outcomes[0].interventionGroup}
                </td>
                <td className="search-results-table__striped_cell">
                  {data.content.outcomes[0].interventionGroupPValue}
                </td>
              </>
            ) : (
              <td colSpan={4} className="search-results-table__striped_cell">
                &nbsp;
              </td>
            )}
            <td rowSpan={data.content.outcomes.length || 1}>
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    shortString(data.content.morningsideSummary)
                  ),
                }}
              />{" "}
              {data.content.morningsideSummary ? (
                <Button
                  as="a"
                  bsPrefix="text-btn"
                  onClick={() => {
                    this.closeButtonClickHandler();
                  }}
                >
                  Read More
                </Button>
              ) : null}
            </td>
          </tr>
          {data.content.outcomes.length > 1
            ? data.content.outcomes.slice(1).map((outcome) => {
                return (
                  <tr key={outcome.outcomeType}>
                    <td className="search-results-table__striped_cell">
                      {outcome.outcomeType}
                      {outcome.primary ? <PrimaryIndicator /> : null}
                    </td>
                    <td className="search-results-table__striped_cell">
                      {outcome.controlGroup}
                    </td>
                    <td className="search-results-table__striped_cell">
                      {outcome.interventionGroup}
                    </td>
                    <td className="search-results-table__striped_cell">
                      {outcome.interventionGroupPValue}
                    </td>
                  </tr>
                );
              })
            : null}
        </>
      );
    }

    return undefined;
  }
}

SearchClinicalTrialTable.propTypes = {
  callbackModal: PropTypes.func,
  data: searchHitPropType,
  type: displayValuePropType,
  cancerStageMapper: PropTypes.func,
  cancerStages: PropTypes.arrayOf(displayValuePropType),
  subtypes: PropTypes.arrayOf(displayValuePropType),
};

SearchClinicalTrialTable.defaultProps = {
  callbackModal: () => {},
  data: undefined,
  type: undefined,
  cancerStageMapper: () => {},
  cancerStages: [],
  subtypes: [],
};

export default SearchClinicalTrialTable;
