import PropTypes from "prop-types";
import { Component } from "react";
import { Button, Col, InputGroup, Row } from "react-bootstrap";
import Variable from "./Variable";
import { inputField } from "../../basic-input";
import { observationalResultOutcomePropType } from "../../../models/propTypes";

const stratumInput = (state, handler) =>
  inputField("Stratum", "strata", state, true, "text", handler);

class Strata extends Component {
  constructor(props, context) {
    super(props, context);
    const { strata } = this.props;
    this.state = {
      strata: strata ? strata.strata : null,
      variables: strata ? strata.variables : [],
    };
  }

  render = () => {
    const { strata, variables } = this.state;
    const { outcomeTypes, statisticRatio } = this.props;
    return (
      <InputGroup style={{ border: "1px solid black", margin: "1em 0em" }}>
        {strata !== "univariate" && (
          <Row>
            {strata !== "multivariate" && (
              <Col>{stratumInput(strata, this.handleChange)}</Col>
            )}
            <Col>
              <Button onClick={this.addVariable}>Add Variable</Button>
            </Col>
            {strata !== "multivariate" && (
              <Col>
                <Button onClick={this.removeStrata}>Remove Strata</Button>
              </Col>
            )}
          </Row>
        )}
        {variables.map((data, i) => (
          <Variable
            key={data.varName}
            data={data}
            onChange={this.handleVariableChange(i)}
            onRemove={this.removeVariable(i)}
            outcomeTypes={outcomeTypes}
            statisticRatio={statisticRatio}
          />
        ))}
      </InputGroup>
    );
  };

  addVariable = () => {
    const { variables: previousVariables } = this.state;
    const variables = [...previousVariables];
    variables.push({
      varName: null,
      outcomes: [],
    });
    this.setState({ variables }, this.runOnChange);
  };

  removeVariable = (index) => () => {
    const { variables: previousVariables } = this.state;
    const variables = previousVariables.filter((x, i) => i !== index);
    this.setState({ variables }, this.runOnChange);
  };

  handleVariableChange = (index) => (variableState) => {
    const { variables: previousVariables } = this.state;
    const variables = [...previousVariables];
    variables[index] = variableState;
    this.setState({ variables }, this.runOnChange);
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.runOnChange
    );
  };

  runOnChange = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(this.state);
    }
  };

  removeStrata = () => {
    const { onRemove } = this.props;
    if (onRemove) {
      onRemove();
    }
  };
}

Strata.propTypes = {
  outcomeTypes: PropTypes.arrayOf(PropTypes.string),
  statisticRatio: PropTypes.string,
  strata: PropTypes.shape({
    strata: PropTypes.string,
    variables: PropTypes.arrayOf(observationalResultOutcomePropType),
  }),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

Strata.defaultProps = {
  outcomeTypes: [],
  statisticRatio: undefined,
  strata: undefined,
  onChange: undefined,
  onRemove: undefined,
};

export default Strata;
