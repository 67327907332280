import { AxiosRequestHeaders } from "axios";
import {
  AxiosCacheInstance,
  CacheAxiosResponse,
  CacheRequestConfig,
} from "axios-cache-interceptor";
import ProxyAsUserService from "../ProxyAsUserService";
import AxiosService, { handleShibbolethTimeoutError } from "./AxiosService";

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
}

export interface ServiceRequest<
  TResponseBody = unknown,
  TRequestBody = unknown
> {
  url: string;
  data?: TRequestBody;
  config?: CacheRequestConfig<TResponseBody, TRequestBody>;
  useProxy?: boolean;
}

export interface AxiosRequest<TResponseBody = unknown, TRequestBody = unknown>
  extends ServiceRequest<TResponseBody, TRequestBody> {
  method: HttpMethod;
  onError?: (error: Record<string, unknown>) => void;
}

const API_PROMISE_CACHE = new Map();

abstract class BaseService {
  private readonly apiPromiseCache: Map<string, Promise<unknown>>;

  constructor(
    public readonly axiosInstance: AxiosCacheInstance,
    protected readonly basePath: string = "/api",
    protected readonly onError: (
      error: Record<string, unknown>
    ) => void = handleShibbolethTimeoutError
  ) {
    this.apiPromiseCache = new Map();
  }

  protected getPath(path?: string): string {
    let fullPath = this.basePath;
    if (path) {
      if (!path.startsWith("/")) {
        fullPath += "/";
      }
      fullPath += path;
    }
    return fullPath;
  }

  public get<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.GET,
    });
  }

  public post<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.POST,
    });
  }

  public put<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.PUT,
    });
  }

  public delete<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.DELETE,
    });
  }

  public patch<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TReponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TReponse> {
    return this.call<TResponseBody, TRequestBody, TReponse>({
      ...request,
      method: HttpMethod.PATCH,
    });
  }

  protected async call<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >({
    method,
    url,
    data = undefined,
    config = {},
    useProxy = true,
  }: AxiosRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.callAxios<TResponseBody, TRequestBody, TResponse>(
      method,
      url,
      data,
      {
        ...config,
        headers: {
          ...(config?.headers || {}),
          ...BaseService.getProxyPpidHeader(useProxy),
        },
      }
    )
      .catch((error) => {
        this.onError?.(error);
        return error;
      })
      .then((result) => {
        if (result instanceof Error) {
          throw result;
        } else {
          return result;
        }
      });
  }

  protected async callAxios<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(
    method: HttpMethod,
    url: string,
    data?: TRequestBody,
    config?: CacheRequestConfig<TResponseBody, TRequestBody>
  ): Promise<TResponse> {
    let response;
    let responsePromise;
    const cachedPromiseKey = JSON.stringify({ method, url, data, config });
    const cachedPromise = this.apiPromiseCache.get(cachedPromiseKey);
    switch (method) {
      case HttpMethod.GET:
        if (cachedPromise) {
          // if endpoint has already been called and is being awaited, return cached promise
          response = cachedPromise;
        } else {
          try {
            // if endpoint has not been called, call endpoint, cache promise, and await response
            responsePromise = this.axiosInstance.get<
              TResponseBody,
              TRequestBody,
              TResponse
            >(url, config);
            this.apiPromiseCache.set(cachedPromiseKey, responsePromise);
            response = await responsePromise;
          } finally {
            // delete cached promise
            if (cachedPromiseKey) {
              this.apiPromiseCache.delete(cachedPromiseKey);
            }
          }
        }
        return response as TResponse;
      case HttpMethod.POST:
        return this.axiosInstance.post<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      case HttpMethod.PUT:
        return this.axiosInstance.put<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      case HttpMethod.DELETE:
        return this.axiosInstance.delete<
          TResponseBody,
          TRequestBody,
          TResponse
        >(url, config);
      case HttpMethod.PATCH:
        return this.axiosInstance.patch<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      default:
        /* istanbul ignore next */
        throw new Error(`Invalid method: ${method}`);
    }
  }

  public static getProxyPpidHeader(useProxy: boolean): AxiosRequestHeaders {
    const proxyPpid = ProxyAsUserService.getProxyPpid();
    const requestHeaders: AxiosRequestHeaders = {};
    if (useProxy && proxyPpid) {
      requestHeaders.proxyPpid = proxyPpid;
    }
    return requestHeaders;
  }

  /**
   * @deprecated Use the instantiated signature
   */
  protected static async callAxios<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(
    method: HttpMethod,
    url: string,
    data?: TRequestBody,
    config?: CacheRequestConfig<TResponseBody, TRequestBody>
  ): Promise<TResponse> {
    let response;
    let responsePromise;
    const cachedPromiseKey = JSON.stringify({ method, url, data, config });
    const cachedPromise = API_PROMISE_CACHE.get(cachedPromiseKey);
    switch (method) {
      case HttpMethod.GET:
        if (cachedPromise) {
          // if endpoint has already been called and is being awaited, return cached promise
          response = cachedPromise;
        } else {
          try {
            // if endpoint has not been called, call endpoint, cache promise, and await response
            responsePromise = AxiosService.get<
              TResponseBody,
              TRequestBody,
              TResponse
            >(url, config);
            API_PROMISE_CACHE.set(cachedPromiseKey, responsePromise);
            response = await responsePromise;
          } finally {
            // delete cached promise
            if (cachedPromiseKey) {
              API_PROMISE_CACHE.delete(cachedPromiseKey);
            }
          }
        }
        return response;
      case HttpMethod.POST:
        return AxiosService.post<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      case HttpMethod.PUT:
        return AxiosService.put<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      case HttpMethod.DELETE:
        return AxiosService.delete<TResponseBody, TRequestBody, TResponse>(
          url,
          config
        );
      case HttpMethod.PATCH:
        return AxiosService.patch<TResponseBody, TRequestBody, TResponse>(
          url,
          data,
          config
        );
      default:
        /* istanbul ignore next */
        throw new Error(`Invalid method: ${method}`);
    }
  }

  /**
   * @deprecated Use the instantiated signature
   */
  protected static async call<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >({
    method,
    url,
    data = undefined,
    config = {},
    useProxy = true,
    onError = handleShibbolethTimeoutError,
  }: AxiosRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.callAxios<TResponseBody, TRequestBody, TResponse>(
      method,
      url,
      data,
      {
        ...config,
        headers: {
          ...(config?.headers || {}),
          ...this.getProxyPpidHeader(useProxy),
        },
      }
    )
      .catch((error) => {
        onError?.(error);
        return error;
      })
      .then((result) => {
        if (result instanceof Error) {
          throw result;
        } else {
          return result;
        }
      });
  }

  /**
   * @deprecated Use the instantiated signature
   */
  public static get<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.GET,
    });
  }

  /**
   * @deprecated Use the instantiated signature
   */
  public static post<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.POST,
    });
  }

  /**
   * @deprecated Use the instantiated signature
   */
  public static put<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.PUT,
    });
  }

  /**
   * @deprecated Use the instantiated signature
   */
  public static delete<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TResponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TResponse> {
    return this.call<TResponseBody, TRequestBody, TResponse>({
      ...request,
      method: HttpMethod.DELETE,
    });
  }

  /**
   * @deprecated Use the instantiated signature
   */
  public static patch<
    TResponseBody = unknown,
    TRequestBody = unknown,
    TReponse = CacheAxiosResponse<TResponseBody, TRequestBody>
  >(request: ServiceRequest<TResponseBody, TRequestBody>): Promise<TReponse> {
    return this.call<TResponseBody, TRequestBody, TReponse>({
      ...request,
      method: HttpMethod.PATCH,
    });
  }
}

export default BaseService;
