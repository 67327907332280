import { FC, ReactElement } from "react";
import FeatureName from "../../../../models/FeatureName";
import ManagedContent from "../../../../modules/shared/src/components/ManagedContent";
import ManagedContentName from "./models/ManagedContentName";

const Footer: FC = (): ReactElement => {
  return (
    <footer id="footer">
      <ManagedContent
        name={ManagedContentName.Content}
        featureName={FeatureName.Footer}
      />
    </footer>
  );
};

export default Footer;
