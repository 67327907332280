import { Link } from "react-router-dom";
import { searchHitPropType } from "../models/propTypes";

const shortenAuthors = (string) => {
  if (!string) {
    return "";
  }
  if (string.length > 128) {
    return `${string.substring(0, 128)}...`;
  }
  return string;
};

const shortenDescription = (string) => {
  if (!string) {
    return "";
  }
  if (string.length > 256) {
    return `${string.substring(0, 256)}...`;
  }
  return string;
};

const SearchresultList = (props) => {
  const { data } = props;

  return (
    <li key={data.id}>
      <Link
        to={{
          pathname: `/${data.index}/${data.id}`,
          state: {
            prevPath: `${window.location.pathname}${window.location.search}`,
          },
        }}
      >
        <h3>{data.content.publication.articleTitle}</h3>
      </Link>
      <p>
        Study Type : {data.index}; Cancer Type: {data.content.cancerType}; Study
        Drug : {data.content.studyDrug};
      </p>
      <p>
        <b>Journal: </b>
        {data.content.publication.journalTitle}
      </p>
      <p>
        <b>Authors: </b>
        {shortenAuthors(data.content.publication.authors)}
      </p>
      <p>{shortenDescription(data.content.morningsideSummary)}</p>
    </li>
  );
};

SearchresultList.propTypes = {
  data: searchHitPropType,
};

SearchresultList.defaultProps = {
  data: undefined,
};

export default SearchresultList;
