import _ from "lodash";

interface FormOption<T = string> {
  value: T;
  label: string;
  disabled?: boolean;
}

export const valueToFormOption = (
  value: string | undefined,
  formOptions?: FormOption[]
): FormOption | undefined => {
  if (!value) {
    return undefined;
  }
  const formOption = (formOptions ?? []).find(
    ({ value: optionValue }) => optionValue === value
  );
  return (
    formOption ?? {
      label: value,
      value,
    }
  );
};

export const formOptionsToSalesforcePicklistString = (
  formOptions?: FormOption[]
): string => {
  if (!formOptions || formOptions.length === 0) {
    return "";
  }
  return (formOptions || [])
    .map(({ value }) => value)
    .filter((x) => x)
    .join(";");
};

export const salesforcePicklistStringToFormOptions = (
  salesforcePicklistString: string | undefined,
  formOptions?: FormOption[]
): FormOption[] => {
  if (!_.isString(salesforcePicklistString)) {
    return [];
  }
  return salesforcePicklistString
    .split(";")
    .map((value) => {
      return valueToFormOption(value, formOptions);
    })
    .filter((option) => !_.isUndefined(option))
    .map((option) => option as FormOption);
};

export default FormOption;
