import { FC, ReactElement } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatureName from "../../models/FeatureName";
import ManagedContent from "../../modules/shared/src/components/ManagedContent";
import ManagedContentName from "./models/ManagedContentName";

const FrequentlyAskedQuestions: FC = (): ReactElement => {
  return (
    <>
      <Breadcrumb id="breadcrumb-bar">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/faq" }} active>
          FAQ
        </Breadcrumb.Item>
      </Breadcrumb>
      <Container id="faq">
        <Row>
          <Col>
            <ManagedContent
              name={ManagedContentName.Content}
              featureName={FeatureName.FrequentlyAskedQuestions}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FrequentlyAskedQuestions;
