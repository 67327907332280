import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { Table } from "react-bootstrap";
import { getGlossary } from "./request";

const buildDefinitionRows = (data) => {
  return (
    <>
      <p>
        <b>Journal Article Definitions:</b> Terminology used in reference to the
        source of a journal article.
      </p>
      <Table size="sm" style={{ color: `black` }}>
        <tbody>
          {data.map((d) => {
            if (d.type === "Journal Article") {
              return (
                <tr key={d.name}>
                  <td>
                    <b>{d.name}</b>
                  </td>
                  <td>{d.definition}</td>
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </Table>
      <p>
        <b>Morningside Center Terminology:</b> Definitions used during the
        process of summarizing data by a Morningside Center team member.
      </p>
      <Table size="sm" style={{ color: `black` }}>
        <tbody>
          {data.map((d) => {
            if (d.type === "Morningside Center") {
              return (
                <tr key={d.name}>
                  <td>
                    <b>{d.name}</b>
                  </td>
                  <td>{d.definition}</td>
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </Table>
    </>
  );
};

class Definitions extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.getGlossary();
  }

  getGlossary = async () => {
    const definitions = await getGlossary();
    if (definitions.status === 200) {
      this.setState({
        loaded: true,
        definitions: definitions.data,
      });
    } else {
      this.setState({
        loaded: true,
        definitions: false,
      });
    }
  };

  render() {
    const { definitions, loaded } = this.state;
    if (loaded === false) {
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="loadingIndicator"
          size="8x"
        />
      );
    }
    if (loaded === true) {
      if (definitions === false) {
        return <p>Please try again</p>;
      }
      if (definitions !== false) {
        return <>{buildDefinitionRows(definitions)}</>;
      }
    }
    return undefined;
  }
}

export default Definitions;
