export const shortString = (string) => {
  if (typeof string === "string") {
    const trimmedString = string.trimEnd();
    if (trimmedString.length > 128) {
      return `${trimmedString.substring(0, 128)}...`;
    }
    return string;
  }
  return string;
};

export const mediumString = (string) => {
  if (!string) {
    return "";
  }
  if (string.length > 256) {
    return `${string.substring(0, 256)}...`;
  }
  return string;
};
