enum SearchParam {
  QUERY_STRING = "q",
  STUDY_TYPE = "index",
  CANCER_TYPE = "cancerType.keyword",
  CANCER_SUBTYPE = "cancerSubType.keyword",
  CONCURRENT_SOC = "concurrentSoc.keyword",
  CANCER_STAGE = "cancerStage.keyword",
  STUDY_DRUG = "studyDrug.keyword",
  DRUG_CLASS = "drugClass.keyword",
  TREATMENT_TIMING = "treatmentTiming.keyword",
  SORT = "sort",
  FROM = "from",
  SIZE = "size",
}

export default SearchParam;
