import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Component } from "react";
import {
  Anchor,
  Navbar as BootstrapNavbar,
  Dropdown,
  DropdownButton,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import emoryLogo from "../images/emorylogoshieldwhite.svg";
import toggleButton from "../images/hamburger.svg";
import ContentManagementUtil from "../modules/shared/src/utils/ContentManagementUtil";

const displayMyFeatures = () => {
  const featuresList = JSON.parse(sessionStorage.getItem(`my_features`));
  if (featuresList) {
    return (
      <>
        <Dropdown.Divider />
        {featuresList.map((feature) => {
          return (
            <p key={feature} className="dropdown-text">
              {feature}
            </p>
          );
        })}
      </>
    );
  }
  return undefined;
};

const handleLogin = () => {
  window.location = `${window.location.origin}/Shibboleth.sso/Login`;
};

const handleLogout = () => {
  localStorage.clear();
  document.cookie = "JSESSION=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  window.location = `${
    window.location.origin
  }/Shibboleth.sso/Logout?return=${encodeURIComponent(window.location.href)}`;
};

class Navbar extends Component {
  renderLoginLogout = () => {
    const { isShibbAuthenticated } = this.props;
    if (isShibbAuthenticated) {
      return (
        <Dropdown.Item onClick={handleLogout}>
          <FontAwesomeIcon icon={faLock} /> Logout
        </Dropdown.Item>
      );
    }
    return (
      <Dropdown.Item onClick={handleLogin}>
        <FontAwesomeIcon icon={faUnlock} /> Login
      </Dropdown.Item>
    );
  };

  render() {
    const { links, netId, title, version, isContentManager } = this.props;
    return (
      <>
        {/* expand="md" sets the breakpoint at 768px */}
        <BootstrapNavbar expand="md">
          <BootstrapNavbar.Brand>
            <Link
              to={{
                pathname: `/`,
              }}
            >
              <img width="70px" height="auto" src={emoryLogo} alt="emorylogo" />
            </Link>
          </BootstrapNavbar.Brand>
          <div className="flex-column">
            <h1>{title}</h1>
            <BootstrapNavbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {links.map((link) => {
                  const key = uuid();
                  if (link.type === "link") {
                    return (
                      <Nav.Link
                        as={Link}
                        key={key}
                        to={{
                          pathname: `/${link.path}`,
                        }}
                      >
                        {link.label}
                      </Nav.Link>
                    );
                  }
                  if (link.type === "anchor") {
                    return (
                      <Nav.Link as="a" key={key} href={link.path}>
                        {link.label}
                      </Nav.Link>
                    );
                  }
                  if (link.type === "modal") {
                    return (
                      <Nav.Link
                        key={key}
                        onClick={() => {
                          const { callbackModal } = this.props;
                          callbackModal();
                        }}
                      >
                        {link.label}
                      </Nav.Link>
                    );
                  }
                  if (link.type === "dropdown") {
                    return (
                      <NavDropdown
                        key={key}
                        title={link.title}
                        id="basic-nav-dropdown"
                      >
                        {link.links.map((childLink) => {
                          const childKey = uuid();
                          if (childLink === "divider") {
                            return <NavDropdown.Divider key={childKey} />;
                          }
                          return (
                            <NavDropdown.Item
                              as={Link}
                              key={childKey}
                              to={{
                                pathname: `/${childLink.path}`,
                              }}
                            >
                              {childLink.label}
                            </NavDropdown.Item>
                          );
                        })}
                      </NavDropdown>
                    );
                  }
                  return undefined;
                })}
              </Nav>
              <Nav>
                {/* show name in dropdown when logged in */}
                {/* show login button when not authenticated */}
                {/*  */}
                {/* <Button>Log In</Button> */}
                {/*  */}
                <DropdownButton
                  align="end"
                  id="dropdown-menu-align-right"
                  title={netId ? netId.toLowerCase() : "Login"}
                >
                  {this.renderLoginLogout()}
                  {isContentManager && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as={Anchor}
                        onClick={() => {
                          ContentManagementUtil.setEnableContentManagementMode(
                            !ContentManagementUtil.isContentManagementModeEnabled()
                          );
                        }}
                      >
                        {ContentManagementUtil.isContentManagementModeEnabled()
                          ? "Disable"
                          : "Enable"}{" "}
                        Content Management
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Divider />
                  <p className="dropdown-text">v{version}</p>
                  {displayMyFeatures()}
                </DropdownButton>
              </Nav>
            </BootstrapNavbar.Collapse>
          </div>
          <BootstrapNavbar.Toggle aria-controls="top-nav">
            <img src={toggleButton} alt="toggle-button" />
          </BootstrapNavbar.Toggle>
        </BootstrapNavbar>
      </>
    );
  }
}

Navbar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
  netId: PropTypes.string,
  isShibbAuthenticated: PropTypes.bool,
  title: PropTypes.string,
  version: PropTypes.string,
  callbackModal: PropTypes.func,
  isContentManager: PropTypes.bool,
};

Navbar.defaultProps = {
  links: [],
  netId: undefined,
  isShibbAuthenticated: false,
  title: undefined,
  version: undefined,
  callbackModal: () => {},
  isContentManager: false,
};

export default Navbar;
