import { FC, ReactElement } from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export type WarningPageProps = {
  message: string;
};

const Warning: FC<WarningPageProps> = ({
  message,
}: WarningPageProps): ReactElement => {
  return (
    <>
      <Breadcrumb id="breadcrumb-bar">
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} active>
          Warning
        </Breadcrumb.Item>
      </Breadcrumb>
      <Container>
        <h1>{message}</h1>
      </Container>
    </>
  );
};

export default Warning;
