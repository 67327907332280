import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/commentBox.scss";
import { addComment, getMyRole } from "./utilities/request";

class CommentBox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      commentBody: "",
      commentError: false,
      userHasPermission: false,
      labelText: "Send a Comment",
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendComment = this.sendComment.bind(this);
  }

  componentDidMount() {
    getMyRole().then((roleInfo) => {
      if (roleInfo.data.features.includes("Remedy Comments")) {
        this.setState({ userHasPermission: true });
      }
    });
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const { commentBody } = this.state;
        if (commentBody !== "") {
          this.setState({ commentError: false });
        }
      }
    );
  }

  async sendComment() {
    const { commentBody, userHasPermission } = this.state;
    if (commentBody === "" || userHasPermission === false) {
      this.setState({ commentError: true });
    } else {
      this.setState({ labelText: "Sending..." });
      const { articlePath } = this.props;

      addComment(commentBody, articlePath)
        .then(() => {
          toast(`Comment Received!`, {
            type: toast.TYPE.SUCCESS,
            className: "outerError",
            bodyClassName: "bodyError",
          });
          this.setState({ labelText: "Send a Comment" });
        })
        .catch((error) => {
          const { data } = error.response;
          toast(
            <>
              <h6>Comment Submission Failed</h6>
              <div className="msg" style={{ marginTop: "1em" }}>
                ${data.message || "No Error Message"}
              </div>
              <pre className="trace">${data.trace}</pre>
            </>,
            {
              type: toast.TYPE.ERROR,
              closeOnClick: false,
              autoClose: false,
              draggable: false,
            }
          );
          this.setState({ labelText: "Send a Comment" });
        });
    }
  }

  render() {
    const { commentError, labelText, userHasPermission } = this.state;
    return (
      <>
        <ToastContainer autoClose={12000} />
        <Form id="commentBox">
          <Form.Label>{labelText}</Form.Label>
          <Form.Control
            as="textarea"
            className={commentError ? "error-color error-animation" : ""}
            name="commentBody"
            defaultValue=""
            disabled={false}
            onChange={this.handleChange}
          />
          <Button onClick={this.sendComment} disabled={!userHasPermission}>
            <FontAwesomeIcon icon={faPaperPlane} className="fas" /> Send
          </Button>
        </Form>
      </>
    );
  }
}

CommentBox.propTypes = {
  articlePath: PropTypes.string,
};

CommentBox.defaultProps = {
  articlePath: undefined,
};

export default CommentBox;
