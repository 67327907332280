import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";

function generateToken(bit) {
  return bit
    ? Math.floor(Math.random() * 16).toString(16)
    : ([1e16] + 1e16).replace(/[01]/g, generateToken);
}

function setCookie(cname, cvalue, exMins) {
  const d = new Date();
  d.setTime(d.getTime() + exMins * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function initCsrf() {
  const token = generateToken();
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
  setCookie("CSRF-TOKEN", token, 24 * 60);
}

export const request = () => {
  const instance = axios.create({
    responseType: "json",
    maxRedirects: 0,
  });
  instance.interceptors.response.use(
    (response) => {
      if (response.status === 302 || response.status === 401)
        window.location.reload();
      return response;
    },
    (error) => {
      let errorMsg;
      if (error.response.status === 401) {
        errorMsg = `401 Error: Shibboleth probably timed out. Please try refreshing your browser.`;
      } else {
        errorMsg = `${error.response.status}: ${error.message}`;
      }
      toast(errorMsg, {
        type: toast.TYPE.ERROR,
        autoClose: false,
      });
      return Promise.reject(error);
    }
  );
  return instance;
};

window.request = request;

/**
 * Makes generic server errors slightly more digestable for humans.
 *
 * TODO should be reformatted on server side, but easier to just to it
 * here until time is allowed to hack through spring's complicated
 * error handling system.
 *
 */
function mapError(err) {
  const errData = err.response.data;
  if (errData) {
    return {
      message: errData.message,
      status: errData.status,
      path: errData.path,
      timestamp: errData.timestamp,
      error: errData.error,
      trace: errData.trace ? errData.trace.split("\n") : [],
    };
  }
  return undefined;
}

export function generalErrorHandler(err) {
  const errorPlus = mapError(err);
  // NOTE error should be thrown instead of returned, so callers of this service can
  // provide context specific handling (turn off spinners, toast errorrs, etc)
  throw new Error(errorPlus);
}

/**
 * Builds request promise functions that have configurable caching
 * options. Cache usage should be transparent to clients of these
 * functions and should treat it like any other promise (i.e.
 * asynchronous, with possible wait times common to the backing rest
 * service.
 *
 * Caches can be cleared by external forces without worry. If the
 * store is missing or corrupted, the emmitted function is capable of
 * falling back to the backing service and restoring the cache.
 *
 * TODO feature idea - add support for timed caches that expire
 * within long sessions. Current implementation either uess
 * sessionStorage (preferred) or the permaCaching abilities of
 * localStorage
 *
 * TODO feature idea - may want to allow endpoint to be a function
 * for endpoints that require variable query params. The requests for
 * which this was originally designed are fixed endpoints for lookup
 * data that changes infrequently.
 *
 * TODO upgrade to class / typescript or other fancy javascript. Not
 * the author's native tongue, so room to improve.
 *
 * @param endpoint - partial url for backing service starting with the
 * URL path (may work with host, port, etc. But not tested as such)
 *
 * @param storeKey - any string used to identify the cache. Should be
 * unique per endpoint/app pair
 *
 * @param options - optional object with config tweaks. Missing
 * options will used documented defaults
 *
 * - infoLogEnabled - boolean (default: false)
 *
 * - store (default: window.sessionStorage)
 *   acceptable values:
 *   + window.sessionStorage, window.localStorage or a substitute with
 *     the same getItem/setItem functions signatures.
 *   + null, undefined, or other falsy values to disable caching
 *
 */
export function CachedRequestBuilder(endpoint, storeKey, options) {
  const store = options?.store ?? window.sessionStorage;

  return () => {
    let storedData = null;

    if (store?.[storeKey]) {
      try {
        storedData = JSON.parse(sessionStorage.getItem(storeKey));
      } catch {
        // do nothing
      }
    }

    if (storedData) {
      return Promise.resolve(storedData);
    }

    return trackPromise(request().get(endpoint))
      .then((data) => {
        if (store) {
          store.setItem(storeKey, JSON.stringify(data));
        }
        return data;
      })
      .catch(generalErrorHandler);
    // TODO will want a generic finally handler that silences spinners and such later
  };
}

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (typeof error.response === "undefined") {
      // https://lifesaver.codes/answer/need-some-advice-about-handling-302-redirects-from-ajax
      window.location = `${window.location.origin}/Shibboleth.sso/Login`;
    }
    return Promise.reject(error);
  }
);

// Like the CHEAT
window.axios = axios;
window.CachedRequestBuilder = CachedRequestBuilder;
initCsrf();
