import moment from "moment";
import { Person } from "../../models";

class ProxyAsUserService {
  static proxyUserCacheKey = "proxyUser";

  static isProxyUser(): boolean {
    return !!ProxyAsUserService.getProxyUser();
  }

  static getProxyPpid(): string | undefined {
    const proxyUser = ProxyAsUserService.getProxyUser() || {};
    return proxyUser.ppid;
  }

  static getProxyUser(): Person {
    const proxyUserCacheEntryAsText = localStorage.getItem(
      ProxyAsUserService.proxyUserCacheKey
    );
    let proxyUser;
    if (proxyUserCacheEntryAsText) {
      const proxyUserCacheEntry = JSON.parse(proxyUserCacheEntryAsText);
      if (
        proxyUserCacheEntry?.value &&
        !!Object.keys(proxyUserCacheEntry?.value).length &&
        proxyUserCacheEntry?.timestamp
      ) {
        const currentTimestamp = moment(new Date().getTime());
        const previousTimeStamp = moment(proxyUserCacheEntry.timestamp);
        if (currentTimestamp.diff(previousTimeStamp, "hours") < 3) {
          proxyUser = proxyUserCacheEntry.value;
          // refresh cache time
          ProxyAsUserService.setProxyUser(proxyUser);
        } else {
          proxyUser = undefined;
        }
      }
    }
    return proxyUser;
  }

  static setProxyUser(user: Person): void {
    localStorage.setItem(
      ProxyAsUserService.proxyUserCacheKey,
      JSON.stringify({
        value: {
          ...user,
          organizationalStatus: user?.personType,
        },
        timestamp: new Date().getTime(),
      })
    );
  }

  static logoutAsProxyUser(): void {
    localStorage.removeItem(ProxyAsUserService.proxyUserCacheKey);
  }
}

export default ProxyAsUserService;
