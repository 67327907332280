/* eslint-disable react/no-danger */
import {
  faBackspace,
  faCircleNotch,
  faFileDownload,
  faList,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTable,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import _ from "lodash";
import { Component } from "react";
import {
  Alert,
  Breadcrumb,
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Pagination,
  Row,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { Link, Route } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import SearchCaseReportTable from "../../components/searchCaseReportTable";
import SearchClinicalTrialTable from "../../components/searchClinicalTrialTable";
import SearchObservationalResultTable from "../../components/searchObservationalResultTable";
import SearchresultList from "../../components/searchresultList";
import PrimaryIndicator from "../../components/utilities/primaryIndicator";
import {
  fetchCancerStagesV2,
  fetchCancerTypeMap,
  fetchCancerTypes,
  fetchClinicalTypesV2,
  fetchConcurrentSocs,
  fetchDrugClasses,
  fetchDrugs,
  fetchIndexLabels,
  fetchTreatmentTimings,
  indexLabel,
  search,
} from "../../components/utilities/request";
import UnpublishedIndicator from "../../components/utilities/unpublishedIndicator";
import MorningsideIndex from "../../models/MorningsideIndex";
import SearchParam from "./models/SearchParam";

const cancerStageMapper = (cancerStages, cancerStage) => {
  return cancerStage
    ?.split(";")
    .map(
      (name) =>
        cancerStages.filter((x) => x.name === name).map((x) => x.label)[0]
    )
    .join("; ");
};

/**
 * state updater for all types of inputs
 */
const updaterForEvent = (event) => {
  const key = event.target.name;
  const val = event.target.value;
  return () => {
    return { [key]: val };
  };
};

const renderLoadingBar = () => {
  return (
    <FontAwesomeIcon
      icon={faCircleNotch}
      className="loadingIndicator"
      size="8x"
    />
  );
};

const renderXlsxTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    The information in the XLSX file is based upon the filters you used for your
    search.
  </Tooltip>
);

class Search extends Component {
  constructor(props, context) {
    super(props, context);
    const tempState = {
      error: null,
      currentTab: MorningsideIndex.CLINICAL_TRIAL,
      searchParams: window.location.search, // NOTE used in detection location change
      location: window.location,
      searchResults: null,
      searchResultsTable: true,
      showFilters: true,
      size: 10,
      cancerTypes: [],
      indexLabels: [],
    };

    this.searchParamNames = _.values(SearchParam);

    const urlParams = new URLSearchParams(window.location.search);
    this.searchParamNames.forEach((paramName) => {
      const paramValue = urlParams.get(paramName);
      if (paramValue) {
        tempState[paramName] = paramValue;
      }
    });
    tempState.searchParamMap = new Map(urlParams.entries()); // NOTE maybe for future use
    this.state = {
      ...tempState,
      currentTab: tempState.index || tempState.currentTab,
    };
  }

  async componentDidMount() {
    const { history } = this.props;
    this.unlisten = history.listen(() => {
      const tempState = {};
      const urlParams = new URLSearchParams(window.location.search);
      this.searchParamNames.forEach((p) => {
        tempState[p] = urlParams.get(p);
      });
      tempState.searchParamMap = new Map(urlParams.entries()); // NOTE maybe for future use
      this.setState(tempState, () => {
        this.executeSearch();
      });
    });

    if (window.location.search) {
      // this will find the sort field and direction and add them to state
      const searchParams = window.location.search;
      const parameterObj = {};
      searchParams.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        ($0, $1, $2, $3) => {
          parameterObj[$1] = $3;
        }
      );
      let sortField = null;
      let sortDirection = null;
      if (parameterObj.sort) {
        sortField = parameterObj.sort.substr(
          0,
          parameterObj.sort.lastIndexOf(":")
        );
        sortDirection = parameterObj.sort.substr(
          parameterObj.sort.lastIndexOf(":") + 1,
          parameterObj.sort.length
        );
      }
      //
      this.setState(
        {
          sortDirection,
          sortField,
        },
        () => {
          this.executeSearch();
        }
      );
    }

    fetchClinicalTypesV2().then((response) =>
      this.setState({ subtypes: response.data })
    );
    fetchIndexLabels().then((response) =>
      this.setState({ indexLabels: response.data })
    );
    fetchCancerTypeMap().then((response) =>
      this.setState({ cancerTypeMap: response.data })
    );
    fetchCancerTypes().then((response) =>
      this.setState({ cancerTypes: response.data })
    );
    fetchCancerStagesV2().then((response) =>
      this.setState({ cancerStages: response.data })
    );
    fetchConcurrentSocs().then((response) =>
      this.setState({ concurrentSOCs: response.data })
    );
    fetchDrugs().then((response) =>
      this.setState({ drugOptions: response.data })
    );
    fetchDrugClasses().then((response) =>
      this.setState({ drugClasses: response.data })
    );
    fetchTreatmentTimings().then((response) =>
      this.setState({ treatmentTimings: response.data })
    );
  }

  async componentWillUnmount() {
    this.unlisten();
  }

  handleCancerTypeChange = (event) => {
    this.setState(
      {
        [SearchParam.CANCER_TYPE]: event.target.value,
        [SearchParam.CANCER_SUBTYPE]: null,
      },
      () => {
        this.pushSearchParams();
        this.executeSearch();
      }
    );
  };

  /**
   * - update state with value of the altered input (i.e. event.target)
   * - best for text input
   */
  handleChange = (event) => {
    this.setState(updaterForEvent(event));
  };

  handleTabSelect = (key) => {
    if (
      key === MorningsideIndex.CASE_REPORT ||
      key === MorningsideIndex.CLINICAL_TRIAL ||
      key === MorningsideIndex.OBSERVATIONAL_RESULT
    ) {
      this.setState({
        currentTab: key,
      });
    }
  };

  handleSort = (field) => {
    const { sortDirection: previousSortDirection } = this.state;
    this.setState(
      {
        sortDirection: `${previousSortDirection === "asc" ? "desc" : "asc"}`,
        sortField: field,
      },
      () => {
        const { sortDirection } = this.state;
        // provide spoofed event.target.name and event.target.value
        let object;
        if (field === "clear") {
          object = { target: { name: "sort", value: `` } };
        } else {
          object = {
            target: {
              name: "sort",
              value: `${field}:${sortDirection}`,
            },
          };
        }
        this.searchOnChange(object);
      }
    );
  };

  handleModalOpen = (summaryText) => {
    this.setState({ showModal: true, modalText: summaryText });
  };

  buildFilterPanel = () => {
    const {
      [SearchParam.DRUG_CLASS]: drugClass,
      drugClasses,
      drugOptions,
      index,
      indexLabels,
      [SearchParam.CANCER_STAGE]: cancerStage,
      cancerStages,
      [SearchParam.CANCER_SUBTYPE]: cancerSubType,
      [SearchParam.CANCER_TYPE]: cancerType,
      cancerTypeMap,
      cancerTypes,
      [SearchParam.CONCURRENT_SOC]: concurrentSoc,
      concurrentSOCs,
      q,
      [SearchParam.STUDY_DRUG]: studyDrug,
      [SearchParam.TREATMENT_TIMING]: treatmentTiming,
      treatmentTimings,
    } = this.state;
    return (
      <div className="searchpage__filter-panel">
        <Form>
          <Row>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Study Type"
                name={SearchParam.STUDY_TYPE}
                options={indexLabels}
                getOptionValue={({ name }) => name}
                value={indexLabels?.find(({ name }) => name === index) ?? null}
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.STUDY_TYPE}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.STUDY_TYPE,
                      value: selectedOption?.name,
                    },
                  });
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Cancer Type"
                name={SearchParam.CANCER_TYPE}
                options={cancerTypes?.map((value) => ({ label: value, value }))}
                value={
                  cancerType ? { label: cancerType, value: cancerType } : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.CANCER_TYPE}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.handleCancerTypeChange({
                    target: {
                      name: SearchParam.CANCER_TYPE,
                      value: selectedOption?.value,
                    },
                  });
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Cancer Subtype"
                name={SearchParam.CANCER_SUBTYPE}
                options={
                  cancerTypeMap && cancerType && cancerTypeMap[cancerType]
                    ? cancerTypeMap[cancerType].map((value) => ({
                        label: value,
                        value,
                      }))
                    : [{ label: "No Subtype Available", isDisabled: true }]
                }
                value={
                  cancerSubType
                    ? { label: cancerSubType, value: cancerSubType }
                    : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.CANCER_SUBTYPE}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.CANCER_SUBTYPE,
                      value: selectedOption?.value,
                    },
                  });
                }}
                isDisabled={
                  !window.location.search.includes(
                    `${SearchParam.CANCER_TYPE}=`
                  )
                }
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Drug"
                name={SearchParam.STUDY_DRUG}
                options={drugOptions?.map((value) => ({ label: value, value }))}
                value={
                  studyDrug ? { label: studyDrug, value: studyDrug } : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.STUDY_DRUG}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.STUDY_DRUG,
                      value: selectedOption?.value,
                    },
                  });
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Drug Category"
                name={SearchParam.DRUG_CLASS}
                options={drugClasses?.map((value) => ({ label: value, value }))}
                value={
                  drugClass ? { label: drugClass, value: drugClass } : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.DRUG_CLASS}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.DRUG_CLASS,
                      value: selectedOption?.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
        <Form>
          <Row>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Stage"
                name={SearchParam.CANCER_STAGE}
                options={cancerStages}
                getOptionValue={({ name }) => name}
                value={
                  cancerStages?.find(({ name }) => name === cancerStage) ?? null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.CANCER_STAGE}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.CANCER_STAGE,
                      value: selectedOption?.name,
                    },
                  });
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Concurrent SOC"
                name={SearchParam.CONCURRENT_SOC}
                options={concurrentSOCs?.map((value) => ({
                  label: value,
                  value,
                }))}
                value={
                  concurrentSoc
                    ? { label: concurrentSoc, value: concurrentSoc }
                    : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.CONCURRENT_SOC}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.CONCURRENT_SOC,
                      value: selectedOption?.value,
                    },
                  });
                }}
              />
            </Col>
            <Col sm={12} md={4} lg={2}>
              <ReactSelect
                placeholder="Treatment Timing"
                name={SearchParam.TREATMENT_TIMING}
                options={treatmentTimings?.map((value) => ({
                  label: value,
                  value,
                }))}
                value={
                  treatmentTiming
                    ? { label: treatmentTiming, value: treatmentTiming }
                    : null
                }
                selectProps={{
                  className: "form-select",
                }}
                classNamePrefix={`searchpage__filter-panel__${SearchParam.TREATMENT_TIMING}`}
                isClearable
                isSearchable
                onChange={(selectedOption) => {
                  this.searchOnChange({
                    target: {
                      name: SearchParam.TREATMENT_TIMING,
                      value: selectedOption?.value,
                    },
                  });
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end" md={12} lg={6}>
              <InputGroup>
                <Form.Control
                  id="general-search"
                  type="text"
                  placeholder="PMID or Keyword"
                  onChange={this.handleChange}
                  value={q || ""}
                  name={SearchParam.QUERY_STRING}
                  className="rounded-start"
                />
                <Button
                  variant="primary"
                  className="text-nowrap"
                  as={Link}
                  to={this.firstPageUrl()}
                >
                  <FontAwesomeIcon icon={faSearch} /> Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  buildResultFilterPanel = (reversed = false) => {
    const {
      currentPage,
      currentTab,
      searchResults,
      searchResultsTable,
      size,
      totalHits,
    } = this.state;
    if (!searchResults) {
      return undefined;
    }
    const hasSearchResults =
      searchResults[MorningsideIndex.CASE_REPORT].content.length > 0 ||
      searchResults[MorningsideIndex.CLINICAL_TRIAL].content.length > 0 ||
      searchResults[MorningsideIndex.OBSERVATIONAL_RESULT].content.length > 0;
    let resultFilterPanel;
    if (hasSearchResults) {
      const currentTabTotalPages = searchResults[currentTab]?.totalPages ?? 0;
      let currentTabCurrentPage = currentPage;
      if (currentPage > currentTabTotalPages) {
        currentTabCurrentPage = 0;
      }
      resultFilterPanel = (
        <Form>
          <Row>
            <Col
              sm={12}
              md={4}
              lg={2}
              className="searchpage__result-filter-panel__result-count-select d-flex align-items-center"
            >
              <InputGroup>
                <InputGroup.Text>Rows per page</InputGroup.Text>
                <Form.Control
                  name={SearchParam.SIZE}
                  type="number"
                  defaultValue={size}
                  onBlur={(event) => {
                    const { target } = event;
                    const newSize = parseInt(target.value, 10);
                    if (!Number.isNaN(newSize) && newSize !== size) {
                      this.searchOnChange({
                        ...event,
                        target: {
                          ...target,
                          value: newSize,
                        },
                      });
                    }
                  }}
                />
                <Dropdown align="end">
                  <Dropdown.Toggle variant="outline-dark" />
                  <Dropdown.Menu>
                    {[10, 25, 50, 100].map((newSize) => (
                      <Dropdown.Item
                        key={newSize}
                        onClick={(event) => {
                          const { target } = event;
                          this.searchOnChange({
                            ...event,
                            target: {
                              ...target,
                              name: "size",
                              value: newSize,
                            },
                          });
                        }}
                      >
                        {newSize}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </InputGroup>
            </Col>
            <Col
              sm={12}
              md={4}
              lg={{ span: 4, offset: 2 }}
              className="searchpage__result-filter-panel__result-count d-flex justify-content-center align-items-center"
            >
              <span className="fw-bolder text-black text-nowrap">
                Total Results: {totalHits}
              </span>
            </Col>
            <Col
              sm={12}
              md={4}
              className="searchpage__result-filter-panel__pagination d-flex justify-content-center justify-content-md-end align-items-center"
            >
              <Pagination aria-label="pagination" className="mb-0">
                <Pagination.First
                  onClick={() => {
                    const { history } = this.props;
                    history.push(this.firstPageUrl());
                  }}
                  active={currentTabCurrentPage === 0}
                >
                  1
                </Pagination.First>
                {/* only shows ellipsis if page 2 is cut off */}
                {currentTabCurrentPage <= 3 ? null : (
                  <Pagination.Ellipsis className="pe-none" />
                )}
                {/* treats totalHits as array, uses index to calculate &from= */}
                {_.range(currentTabTotalPages).map((index) => {
                  // prevents duplicate first and last buttons
                  if (index !== 0 && index !== currentTabTotalPages - 1) {
                    // hides buttons less too small or too big
                    if (
                      index >= currentTabCurrentPage - 2 &&
                      index <= currentTabCurrentPage + 2
                    ) {
                      return (
                        <Pagination.Item
                          key={index}
                          onClick={() => {
                            const { history } = this.props;
                            history.push(this.nthPageUrl(index));
                          }}
                          active={currentTabCurrentPage === index}
                        >
                          {index + 1}
                        </Pagination.Item>
                      );
                    }
                  }
                  return null;
                })}
                {/* only shows ellipsis if total pages minus one (currentTabTotalPages - 1 -1) button is cut off */}
                {currentTabCurrentPage >= currentTabTotalPages - 4 ? null : (
                  <Pagination.Ellipsis className="pe-none" />
                )}
                {/* links to last page */}
                {currentTabTotalPages > 1 ? (
                  <Pagination.Last
                    onClick={() => {
                      const { history } = this.props;
                      history.push(this.nthPageUrl(currentTabTotalPages - 1));
                    }}
                    active={currentTabCurrentPage === currentTabTotalPages - 1}
                  >
                    {currentTabTotalPages}
                  </Pagination.Last>
                ) : null}
              </Pagination>
            </Col>
          </Row>
        </Form>
      );
    }
    const clearButtons = (
      <Col className="mt-3">
        <ButtonGroup className="w-auto">
          {this.clearSortingButton()}
          {this.clearSearchButton()}
        </ButtonGroup>
      </Col>
    );
    const otherButtons = (
      <Col className="mt-3 d-flex justify-content-end">
        <ButtonGroup className="me-sm-2">
          <Button
            variant={searchResultsTable ? "primary" : "outline-primary"}
            onClick={() => this.setState({ searchResultsTable: true })}
            className="text-nowrap"
          >
            <FontAwesomeIcon icon={faTable} /> Table
          </Button>
          <Button
            variant={searchResultsTable ? "outline-primary" : "primary"}
            onClick={() => this.setState({ searchResultsTable: false })}
            className="text-nowrap"
          >
            <FontAwesomeIcon icon={faList} /> List
          </Button>
        </ButtonGroup>
        <a href={this.xlsxUrl()}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={renderXlsxTooltip}
          >
            <Button className="searchpage__result-filter-panel__xlsx-download-button text-nowrap">
              XLSX <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </OverlayTrigger>
        </a>
      </Col>
    );
    const featuresList = sessionStorage.getItem(`my_features`);
    const resultTableLegend = (
      <>
        {featuresList?.includes("Remedy Article Editing") ||
        featuresList?.includes("Remedy Article Publishing") ? (
          <Row className="searchpage__result-table-legend">
            <Col className="mt-3">
              <span style={{ fontSize: "10pt" }}>
                (<UnpublishedIndicator /> = Unpublished)
              </span>
            </Col>
          </Row>
        ) : undefined}
      </>
    );
    return (
      <div className="searchpage__result-filter-panel">
        {!reversed && (
          <>
            <Row>
              {clearButtons}
              {otherButtons}
            </Row>
            {resultTableLegend}
          </>
        )}
        {resultFilterPanel}
      </div>
    );
  };

  buildSearchResultsTabString = (type) => {
    const { searchResults } = this.state;
    if (searchResults[type].numberOfElements === 0) {
      return `— of ${searchResults[type].totalElements}`;
    }
    const currentPageStart =
      (searchResults[type].pageable.pageNumber + 1) *
        searchResults[type].pageable.pageSize -
      searchResults[type].pageable.pageSize +
      1;
    const potentialCurrentPageEnd =
      (searchResults[type].pageable.pageNumber + 1) *
      searchResults[type].pageable.pageSize;
    const typeTotal = searchResults[type].totalElements;
    let currentPageEnd;
    if (potentialCurrentPageEnd > typeTotal) {
      currentPageEnd = typeTotal;
    } else {
      currentPageEnd = potentialCurrentPageEnd;
    }
    return `${currentPageStart}-${currentPageEnd} of ${typeTotal}`;
  };

  buildSearchResults = () => {
    const {
      cancerStages,
      currentTab,
      indexLabels,
      searchResults,
      searchResultsTable,
      subtypes,
    } = this.state;
    // if there are results
    if (searchResults) {
      if (
        searchResults[MorningsideIndex.CASE_REPORT].content.length > 0 ||
        searchResults[MorningsideIndex.CLINICAL_TRIAL].content.length > 0 ||
        searchResults[MorningsideIndex.OBSERVATIONAL_RESULT].content.length > 0
      ) {
        return (
          <>
            <Tabs
              id="results-tables"
              defaultActiveKey={currentTab}
              onSelect={this.handleTabSelect}
            >
              {/* Case Report Tab */}
              <Tab
                eventKey={MorningsideIndex.CASE_REPORT}
                title={`${indexLabel(
                  indexLabels,
                  MorningsideIndex.CASE_REPORT
                )} (${this.buildSearchResultsTabString(
                  MorningsideIndex.CASE_REPORT
                )})`}
              >
                {searchResultsTable ? (
                  <Table id="search-results-table" striped bordered>
                    <thead>
                      <tr>
                        <th>
                          PMID
                          <hr />
                          Year{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(
                                "publication.publicationYear.keyword"
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                `publication.publicationYear.keyword`
                              )}
                            />
                          </button>
                        </th>
                        <th>Study Type</th>
                        <th>
                          Stage{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_STAGE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_STAGE
                              )}
                            />
                          </button>
                          <hr />
                          Cancer Type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_TYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_TYPE
                              )}
                            />
                          </button>
                          <hr />
                          Sub-type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_SUBTYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_SUBTYPE
                              )}
                            />
                          </button>
                        </th>
                        <th>
                          Drug Name{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.STUDY_DRUG)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(SearchParam.STUDY_DRUG)}
                            />
                          </button>
                          <hr />
                          Drug Category
                        </th>
                        <th>
                          Treatment Timing{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.TREATMENT_TIMING)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.TREATMENT_TIMING
                              )}
                            />
                          </button>
                        </th>
                        <th>Dosage</th>
                        <th>
                          Concurrent SOC{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CONCURRENT_SOC)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CONCURRENT_SOC
                              )}
                            />
                          </button>
                        </th>
                        <th>
                          Number of Patients{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() => this.handleSort("numberOfPatients")}
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(`numberOfPatients`)}
                            />
                          </button>
                        </th>
                        <th>Case Outcomes</th>
                        <th>Morningside Center Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults[MorningsideIndex.CASE_REPORT].searchHits
                        .searchHits?.length ? (
                        <>
                          {searchResults[
                            MorningsideIndex.CASE_REPORT
                          ].searchHits.searchHits.map((hit) => {
                            const type = indexLabels.find(
                              (element) =>
                                element.name === MorningsideIndex.CASE_REPORT
                            );
                            return (
                              <SearchCaseReportTable
                                key={hit.id}
                                data={hit}
                                callbackModal={this.handleModalOpen}
                                type={type}
                                cancerStageMapper={cancerStageMapper}
                                cancerStages={cancerStages}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <tr>
                          <td colSpan="100%">
                            <Alert
                              variant="info"
                              className="text-center fw-bold mb-0"
                            >
                              No results found
                            </Alert>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <ul id="search-results-list">
                    {searchResults[MorningsideIndex.CASE_REPORT].searchHits
                      .searchHits?.length ? (
                      <>
                        {searchResults[
                          MorningsideIndex.CASE_REPORT
                        ].searchHits.searchHits.map((hit) => {
                          return <SearchresultList key={hit.id} data={hit} />;
                        })}
                      </>
                    ) : (
                      <Alert
                        variant="info"
                        className="text-center fw-bold mb-0"
                      >
                        No results found
                      </Alert>
                    )}
                  </ul>
                )}
              </Tab>
              {/* Clinical Trial Tab */}
              <Tab
                eventKey={MorningsideIndex.CLINICAL_TRIAL}
                title={`${indexLabel(
                  indexLabels,
                  MorningsideIndex.CLINICAL_TRIAL
                )} (${this.buildSearchResultsTabString(
                  MorningsideIndex.CLINICAL_TRIAL
                )})`}
              >
                {searchResultsTable ? (
                  <Table id="search-results-table" striped bordered>
                    <thead>
                      <tr>
                        <th rowSpan="2" colSpan="1">
                          PMID
                          <hr />
                          Year{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(
                                "publication.publicationYear.keyword"
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                `publication.publicationYear.keyword`
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Study Type
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Stage{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_STAGE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_STAGE
                              )}
                            />
                          </button>
                          <hr />
                          Cancer Type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_TYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_TYPE
                              )}
                            />
                          </button>
                          <hr />
                          Sub-type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_SUBTYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_SUBTYPE
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Drug Name{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.STUDY_DRUG)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(SearchParam.STUDY_DRUG)}
                            />
                          </button>
                          <hr />
                          Drug Category
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Treatment Timing{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.TREATMENT_TIMING)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.TREATMENT_TIMING
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Dosage
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Concurrent SOC{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CONCURRENT_SOC)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CONCURRENT_SOC
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Number of Patients{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() => this.handleSort("numberOfPatients")}
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(`numberOfPatients`)}
                            />
                          </button>
                        </th>
                        <th
                          rowSpan="1"
                          colSpan="4"
                          style={{ textAlign: "center" }}
                        >
                          Trial Outcomes{" "}
                          <span style={{ fontSize: "10pt" }}>
                            (<PrimaryIndicator /> = Primary)
                          </span>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Morningside Center Summary
                        </th>
                      </tr>
                      <tr>
                        <th className="outcome-column">Type</th>
                        <th className="outcome-column">Control</th>
                        <th className="outcome-column">Intervention</th>
                        <th className="outcome-column">pVal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subtypes &&
                      !!searchResults[MorningsideIndex.CLINICAL_TRIAL]
                        .searchHits.searchHits.length ? (
                        searchResults[
                          MorningsideIndex.CLINICAL_TRIAL
                        ].searchHits.searchHits.map((hit) => {
                          const type = indexLabels.find(
                            (element) =>
                              element.name === MorningsideIndex.CLINICAL_TRIAL
                          );
                          return (
                            <SearchClinicalTrialTable
                              key={hit.id}
                              data={hit}
                              callbackModal={this.handleModalOpen}
                              type={type}
                              subtypes={subtypes}
                              cancerStageMapper={cancerStageMapper}
                              cancerStages={cancerStages}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%">
                            <Alert
                              variant="info"
                              className="text-center fw-bold mb-0"
                            >
                              No results found
                            </Alert>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <ul id="search-results-list">
                    {searchResults[MorningsideIndex.CLINICAL_TRIAL].searchHits
                      .searchHits?.length ? (
                      <>
                        {searchResults[
                          MorningsideIndex.CLINICAL_TRIAL
                        ].searchHits.searchHits.map((hit) => {
                          return <SearchresultList key={hit.id} data={hit} />;
                        })}
                      </>
                    ) : (
                      <Alert
                        variant="info"
                        className="text-center fw-bold mb-0"
                      >
                        No results found
                      </Alert>
                    )}
                  </ul>
                )}
              </Tab>
              {/* Observational Result Tab */}
              <Tab
                eventKey={MorningsideIndex.OBSERVATIONAL_RESULT}
                title={`${indexLabel(
                  indexLabels,
                  MorningsideIndex.OBSERVATIONAL_RESULT
                )} (${this.buildSearchResultsTabString(
                  MorningsideIndex.OBSERVATIONAL_RESULT
                )})`}
              >
                {searchResultsTable ? (
                  <Table id="search-results-table" striped bordered>
                    <thead>
                      <tr>
                        <th rowSpan="2" colSpan="1">
                          PMID
                          <hr />
                          Year{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(
                                "publication.publicationYear.keyword"
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                "publication.publicationYear.keyword"
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Study Type
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Stage{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_STAGE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_STAGE
                              )}
                            />
                          </button>
                          <hr />
                          Cancer Type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_TYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_TYPE
                              )}
                            />
                          </button>
                          <hr />
                          Sub-type{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CANCER_SUBTYPE)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CANCER_SUBTYPE
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Drug Name{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.STUDY_DRUG)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(SearchParam.STUDY_DRUG)}
                            />
                          </button>
                          <hr />
                          Drug Category
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Treatment Timing{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.TREATMENT_TIMING)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.TREATMENT_TIMING
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Dosage
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Concurrent SOC{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() =>
                              this.handleSort(SearchParam.CONCURRENT_SOC)
                            }
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(
                                SearchParam.CONCURRENT_SOC
                              )}
                            />
                          </button>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Number of Patients{" "}
                          <button
                            type="button"
                            className="column-sort-button"
                            onClick={() => this.handleSort("numberOfPatients")}
                          >
                            <FontAwesomeIcon
                              icon={this.chooseSortIcon(`numberOfPatients`)}
                            />
                          </button>
                        </th>
                        <th
                          rowSpan="1"
                          colSpan="4"
                          style={{ textAlign: "center" }}
                        >
                          Outcomes{" "}
                          <span style={{ fontSize: "10pt" }}>
                            (<PrimaryIndicator /> = Primary)
                          </span>
                        </th>
                        <th rowSpan="2" colSpan="1">
                          Morningside Center Summary
                        </th>
                      </tr>
                      <tr>
                        <th>Variable</th>
                        <th>Type</th>
                        <th>HR</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults[MorningsideIndex.OBSERVATIONAL_RESULT]
                        .searchHits.searchHits.length ? (
                        searchResults[
                          MorningsideIndex.OBSERVATIONAL_RESULT
                        ].searchHits.searchHits.map((hit) => {
                          const type = indexLabels.find(
                            (element) =>
                              element.name ===
                              MorningsideIndex.OBSERVATIONAL_RESULT
                          );
                          return (
                            <SearchObservationalResultTable
                              key={hit.id}
                              data={hit}
                              callbackModal={this.handleModalOpen}
                              type={type}
                              cancerStageMapper={cancerStageMapper}
                              cancerStages={cancerStages}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="100%">
                            <Alert
                              variant="info"
                              className="text-center fw-bold mb-0"
                            >
                              No results found
                            </Alert>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <ul id="search-results-list">
                    {searchResults[MorningsideIndex.OBSERVATIONAL_RESULT]
                      .searchHits.searchHits?.length ? (
                      <>
                        {searchResults[
                          MorningsideIndex.OBSERVATIONAL_RESULT
                        ].searchHits.searchHits.map((hit) => {
                          return <SearchresultList key={hit.id} data={hit} />;
                        })}
                      </>
                    ) : (
                      <Alert
                        variant="info"
                        className="text-center fw-bold mb-0"
                      >
                        No results found
                      </Alert>
                    )}
                  </ul>
                )}
              </Tab>
            </Tabs>
          </>
        );
      }
      // if the search was successful but no request
      if (
        searchResults[MorningsideIndex.CASE_REPORT].content.length === 0 &&
        searchResults[MorningsideIndex.CLINICAL_TRIAL].content.length === 0 &&
        searchResults[MorningsideIndex.OBSERVATIONAL_RESULT].content.length ===
          0
      ) {
        return <h3>No results found in ReMeDy-Cancer.</h3>;
      }
    }
    return null;
  };

  chooseSortIcon = (field) => {
    const { sortDirection, sortField } = this.state;
    if (sortField === field) {
      if (sortDirection === "asc") {
        return faSortDown;
      }
      if (sortDirection === "desc") {
        return faSortUp;
      }
    }
    return faSort;
  };

  clearSearchButton = () => {
    const { searchResults } = this.state;
    if (searchResults) {
      return (
        <Button
          variant="primary"
          onClick={() =>
            this.setState(
              {
                searchResults: null,
                q: undefined,
                // 'index' is 'study type'
                index: undefined,
                [SearchParam.CANCER_TYPE]: undefined,
                [SearchParam.CANCER_SUBTYPE]: undefined,
                [SearchParam.STUDY_DRUG]: undefined,
                [SearchParam.DRUG_CLASS]: undefined,
                [SearchParam.CANCER_STAGE]: undefined,
                [SearchParam.CONCURRENT_SOC]: undefined,
                [SearchParam.TREATMENT_TIMING]: undefined,
              },
              () =>
                window.history.pushState({}, document.title, "/multi-search")
            )
          }
          className="text-nowrap"
        >
          <FontAwesomeIcon icon={faBackspace} /> Clear Search
        </Button>
      );
    }

    return undefined;
  };

  clearSortingButton = () => {
    const { searchResults } = this.state;
    if (searchResults && window.location.search.includes(`sort=`)) {
      return (
        <Button
          id="clear-sort-btn"
          onClick={() => this.handleSort("clear")}
          className="text-nowrap"
        >
          <FontAwesomeIcon icon={faUndoAlt} /> Reset Sort
        </Button>
      );
    }

    return undefined;
  };

  /** for use in links */
  nthPageUrl = (index) => {
    const usp = new URLSearchParams(this.searchParamString());
    usp.set(SearchParam.FROM, Math.max(index, 0));
    return `${window.location.pathname}?${usp.toLocaleString()}`;
  };

  /** for use in links */
  firstPageUrl = () => {
    return this.nthPageUrl(0);
  };

  /** for general use */
  searchUrl = () => {
    return `${window.location.pathname}?${this.searchParamString()}`;
  };

  xlsxUrl = () => {
    return `api/export/xlsx?${this.searchParamString()}`;
  };

  executeSearch = async () => {
    this.setState({ searchInProgress: true });

    return search(this.searchUrl())
      .then((searchResults) => {
        window.sr = searchResults;
        const { currentTab } = this.state;
        this.setState({
          currentPage: searchResults.data[currentTab].pageable.pageNumber,
          searchResults: searchResults.data,
          showModal: false,
          totalHits:
            searchResults.data[MorningsideIndex.CASE_REPORT].searchHits
              .totalHits +
            searchResults.data[MorningsideIndex.CLINICAL_TRIAL].searchHits
              .totalHits +
            searchResults.data[MorningsideIndex.OBSERVATIONAL_RESULT].searchHits
              .totalHits,
        });
      })
      .catch((e) => {
        toast(`${e}`, { type: toast.TYPE.ERROR });
      })
      .finally(() => {
        const { index, currentTab } = this.state;
        this.setState({
          searchInProgress: false,
          currentTab: index ?? currentTab,
        });
      });
  };

  /**
   * URL query string from current state
   */
  searchParamString = () => {
    const params = new URLSearchParams();
    this.searchParamNames.forEach((paramName) => {
      const { [paramName]: initialParamValue } = this.state;
      if (initialParamValue) {
        let paramValue = initialParamValue;
        if (paramName === SearchParam.FROM) {
          paramValue = Math.max(paramValue, 0);
        }
        params.set(paramName, paramValue);
      }
    });
    return decodeURIComponent(params.toLocaleString());
  };

  /**
   * pushes a new location onto the history, where page URL is
   * build from latest component state.
   *
   * - will not trigger a document load or requests
   * - intended for use in tandem with executeSearch
   */
  pushSearchParams = () => {
    window.history.pushState(
      window.history.state,
      document.title,
      this.searchUrl()
    );
  };

  /**
   * - update state with value of the altered input (i.e. event.target)
   * - then push search params to history and run search with these params
   * - lovely for dropdowns
   * - not so bad for text input if partial search enabled
   */
  searchOnChange = (event) => {
    this.setState(updaterForEvent(event), () => {
      this.pushSearchParams();
      this.executeSearch();
    });
  };

  render() {
    const { modalText, showModal, searchInProgress, searchResultsTable } =
      this.state;
    return (
      <div className="searchpage">
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/search" }} active>
            Search
          </Breadcrumb.Item>
        </Breadcrumb>

        <Modal
          size="lg"
          show={showModal}
          onHide={() => this.setState({ showModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <h2>Morningside Summary</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(modalText),
              }}
            />
          </Modal.Body>
        </Modal>

        <Container
          id="searchpage-container"
          className={`container-${searchResultsTable ? "table" : "cards"}`}
        >
          {searchInProgress ? null : this.buildFilterPanel()}
          {searchInProgress ? null : this.buildResultFilterPanel()}
          {searchInProgress ? (
            renderLoadingBar()
          ) : (
            <div className="searchpage__result-table">
              <Row>
                <Col className="mt-3">{this.buildSearchResults()}</Col>
              </Row>
            </div>
          )}
          {searchInProgress ? null : this.buildResultFilterPanel(true)}
        </Container>
      </div>
    );
  }
}

Search.propTypes = Route.propTypes;

export default Search;
