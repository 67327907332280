import { Component } from "react";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * @deprecated This page is unused in the UI and can probably be removed
 */
class Demopage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <>
        <Breadcrumb id="breadcrumb-bar">
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/demo" }} active>
            Demo
          </Breadcrumb.Item>
        </Breadcrumb>
        <Container>
          <>
            <Button variant="primary">Primary</Button>{" "}
            <Button variant="secondary">Secondary</Button>{" "}
            <Button variant="success">Success</Button>{" "}
            <Button variant="warning">Warning</Button>{" "}
            <Button variant="danger">Danger</Button>{" "}
            <Button variant="info">Info</Button>{" "}
            <Button variant="light">Light</Button>{" "}
            <Button variant="dark">Dark</Button>{" "}
            <Button variant="link">Link</Button>
          </>
          <br />
          <br />
          <ButtonGroup aria-label="Basic example">
            <Button variant="secondary">Left</Button>
            <Button variant="secondary">Middle</Button>
            <Button variant="secondary">Right</Button>
          </ButtonGroup>
          <br />
          <br />
          <Card style={{ width: "18rem" }}>
            <Card.Img variant="top" src="https://i.redd.it/jstk6xslq2o61.jpg" />
            <Card.Body>
              <Card.Title>Card Title</Card.Title>
              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card&apos;s content.
              </Card.Text>
              <Button variant="primary">Go somewhere</Button>
            </Card.Body>
          </Card>
        </Container>
      </>
    );
  }
}

export default Demopage;
