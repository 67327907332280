import { studyPropType } from "../models/propTypes";

const orTypeDisplay = (key) => {
  let abbrev = key;
  let complete = key;
  switch (key) {
    case "cancerSpecificMortality":
      abbrev = "CSM";
      complete = "Cancer Specific Mortality";
      break;
    case "cancerSpecificSurvival":
      abbrev = "CSS";
      complete = "Cancer Specific Survival";
      break;
    case "diseaseFreeSurvival":
      abbrev = "DFS";
      complete = "Disease Free Survival";
      break;
    case "overallMortality":
      abbrev = "OM";
      complete = "Overall Mortality";
      break;
    case "overallSurvival":
      abbrev = "OS";
      complete = "Overall Survival";
      break;
    case "progressionFreeSurvival":
      abbrev = "PFS";
      complete = "Progression Free Survival";
      break;
    case "recurrenceFreeSurvival":
      abbrev = "RFS";
      complete = "Recurrence Free Survival";
      break;
    case "riskOfRecurrence":
      abbrev = "RR";
      complete = "Risk Of Recurrence";
      break;
    default:
  }

  return <div title={complete}>{abbrev}</div>;
};

const OutcomesDisplay = (props) => {
  const { data } = props;
  return (
    <div>
      {
        /* NOTE: probably want to refactor this so it can be used on the other pages
          and certainly could use more style, but should now be clear how this one
          works.
      */
        data.index === "observational-result" &&
          data.outcomes &&
          data.outcomes.map((x) => (
            <div key={`${x.strata}:${x.varName}`}>
              <h5>
                {x.strata} : {x.varName}
              </h5>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>HR</th>
                    <th>Upper CI</th>
                    <th>Lower CI</th>
                    <th>Total</th>
                    <th>Deaths</th>
                  </tr>
                </thead>
                <tbody>
                  {x.outcomes.map((row) => (
                    <tr key={row.type}>
                      <td>{orTypeDisplay(row.type)}</td>
                      <td>{row.hr}</td>
                      <td>{row.upperCI}</td>
                      <td>{row.lowerCI}</td>
                      <td>{row.total}</td>
                      <td>{row.deaths}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
            </div>
          ))
      }

      {
        /*
      The outcomes actually appears in a more structured field called cases
      Like will want to display cases in a table format on individual details
      page. Since that info is already in this table, just showing outcome text
      here. Of course, it is total guess work.
    */
        data.index === "case-report" &&
          data.outcomes &&
          data.outcomes.map((x, i) => (
            <div key={x}>
              <p>{x}</p>
              {data.outcomes.length - 1 !== i ? <hr /> : null}
            </div>
          ))
      }

      {data.index === "clinical-trial" &&
        data.outcomes &&
        data.outcomes.length > 0 && (
          <table className="outcomes-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Control</th>
                <th>Intervention</th>
                <th>P Value</th>
              </tr>
            </thead>
            <tbody>
              {data.outcomes.map((row) => (
                <tr key={row.outcomeType}>
                  <td>{row.outcomeType}</td>
                  <td>{row.controlGroup}</td>
                  <td>{row.interventionGroup}</td>
                  <td>{row.interventionGroupPValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
};

OutcomesDisplay.propTypes = {
  data: studyPropType,
};

OutcomesDisplay.defaultProps = {
  data: {
    index: undefined,
    outcomes: [],
  },
};

export default OutcomesDisplay;
