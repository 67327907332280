import { faPoll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { Component } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { fetchSurveys } from "./utilities/request";

class NewcastleSurvey extends Component {
  constructor(props, context) {
    super(props, context);
    window.nc = this;
    this.state = {
      surveyData: [],
      surveyForm: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentDidMount() {
    fetchSurveys().then((x) =>
      this.setState({ surveyForm: x.data }, () => {
        const { surveyResponse } = this.props;
        if (surveyResponse?.answers)
          this.setState({ surveyData: surveyResponse.answers });
      })
    );
  }

  componentDidUpdate(prevProps) {
    const { surveyResponse: previousSurveyResponse } = prevProps;
    const { surveyResponse } = this.props;
    if (surveyResponse && previousSurveyResponse !== surveyResponse) {
      this.setState({ surveyData: surveyResponse?.answers });
    }
  }

  handleChange(event) {
    const answer = {
      questionId: event.target.name,
    };
    const answerValue = event.target.value;
    const questionType = this.questionType(answer.questionId);

    switch (questionType) {
      case "Number":
      case "Toggle":
        answer.numberValue = answerValue;
        break;
      case "Percent":
        answer.percentValue = answerValue;
        break;
      case "Picklist":
        answer.choiceId = answerValue;
        break;
      default:
        break;
    }

    const { surveyData } = this.state;
    const { surveyResponseId, surveyHandler } = this.props;

    const newSurveyData = [...surveyData].filter(
      (x) => x.questionId !== answer.questionId
    );
    newSurveyData.push(answer);
    this.setState(
      {
        surveyData: newSurveyData,
      },
      () =>
        this.updateSurveyScore(() => {
          const { surveyData: answers } = this.state;
          surveyHandler({
            id: surveyResponseId,
            surveyId: this.surveyId(),
            answers,
          });
        })
    );
  }

  handleTypeChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  surveyForm() {
    const type = this.surveyType();
    const { surveyForm } = this.state;
    return surveyForm?.[type] ? surveyForm[type] : [];
  }

  surveyId() {
    return this.surveyForm() ? this.surveyForm()[0].surveyId : null;
  }

  buildSurveyFields() {
    const surveyForm = this.surveyForm();
    return (
      <>
        {surveyForm.map((q) => {
          return this.buildSurveyQuestion(q);
        })}
      </>
    );
  }

  surveyQuestion(questionId) {
    return this.surveyForm().find((q) => q.id === questionId);
  }

  questionType(questionId) {
    const question = this.surveyQuestion(questionId);
    return question ? question.type : null;
  }

  surveyScore() {
    const scoreQuestionId = this.scoreQuestionId();
    const { surveyData } = this.state;
    return surveyData
      .filter((x) => x.questionId !== scoreQuestionId)
      .map((x) => this.questionScoreValue(x))
      .reduce((a, b) => a + b, 0);
  }

  updateSurveyScore(callback) {
    const scoreId = this.scoreQuestionId();
    const { surveyData } = this.state;
    const data = surveyData.filter((x) => x.questionId !== scoreId);
    data.push({ questionId: scoreId, numberValue: this.surveyScore() });
    this.setState({ surveyData: data }, callback);
  }

  scoreQuestionId() {
    return this.surveyForm().find(
      (x) => x.text === "Newcastle-Ottawa Quality Assessment Scale Score"
    ).id;
  }

  questionScoreValue(answer) {
    const { questionId } = answer;
    const question = this.surveyQuestion(questionId);
    const questionType = this.questionType(questionId);
    switch (questionType) {
      case "Picklist": {
        const choice = question.choices.find((x) => x.id === answer.choiceId);
        return choice ? parseFloat(choice.value) : 0;
      }
      case "Toggle":
        return parseFloat(answer.numberValue);
      case "Number":
      case "Percent":
      default:
        return 0;
    }
  }

  questionValue(questionId) {
    const { surveyData } = this.state;
    const answer = surveyData.find((x) => x.questionId === questionId);
    if (answer === undefined) {
      return undefined;
    }
    const questionType = this.questionType(questionId);
    switch (questionType) {
      case "Picklist":
        return answer.choiceId;
      case "Toggle":
      case "Number":
        return answer.numberValue;
      case "Percent":
        return answer.percentValue;
      default:
        return undefined;
    }
  }

  buildSurveyQuestion(question) {
    switch (question.type) {
      case "Picklist": {
        const options = question.choices.map(({ id, text }) => {
          return {
            label: text,
            value: id,
          };
        });
        const selectedOption =
          options.find(
            ({ value }) => this.questionValue(question.id) === value
          ) ?? null;
        return (
          <div className="survey-group" key={question.id}>
            <Form.Label>{question.text}</Form.Label>
            <ReactSelect
              placeholder={question.text}
              name={question.id}
              options={options}
              value={selectedOption}
              selectProps={{
                className: "form-select",
              }}
              isClearable
              isSearchable
              onChange={(newSelectedOption) => {
                this.handleChange({
                  target: {
                    name: question.id,
                    value: newSelectedOption?.value,
                  },
                });
              }}
            />
          </div>
        );
      }
      case "Toggle": {
        const options = [
          {
            label: "Yes",
            value: 1,
          },
          {
            label: "No",
            value: 0,
          },
        ];
        const selectedOption =
          options.find(
            ({ value }) => this.questionValue(question.id) === value
          ) ?? null;
        return (
          <div className="survey-group" key={question.id}>
            <Form.Label>{question.text}</Form.Label>
            <ReactSelect
              placeholder={question.text}
              name={question.id}
              options={options}
              value={selectedOption}
              selectProps={{
                className: "form-select",
              }}
              isClearable
              isSearchable
              onChange={(newSelectedOption) => {
                this.handleChange({
                  target: {
                    name: question.id,
                    value: newSelectedOption?.value,
                  },
                });
              }}
            />
          </div>
        );
      }
      case "Number":
      case "Percent":
        return (
          <div className="survey-group" key={question.id}>
            <Form.Label>{question.text}</Form.Label>
            <Form.Control
              as="input"
              type="number"
              readOnly={
                question.text ===
                "Newcastle-Ottawa Quality Assessment Scale Score"
              }
              name={question.id}
              value={this.questionValue(question.id) ?? undefined}
              onChange={this.handleChange}
            />
          </div>
        );
      default:
        return null;
    }
  }

  surveyType() {
    const { articleType } = this.props;
    if (articleType === "Case-control study") {
      return "Case-Control";
    }

    if (articleType?.startsWith("Cohort")) {
      return "Cohort";
    }

    return null;
  }

  render() {
    const { articleType } = this.props;
    if (articleType === undefined) {
      return null;
    }

    if (
      articleType === "Case-control study" ||
      articleType?.startsWith("Cohort")
    ) {
      return (
        <>
          <div id="newcastle-survey" className="mb-3">
            <h2>
              <FontAwesomeIcon icon={faPoll} className="fas" /> Newcastle Survey
            </h2>
            {this.buildSurveyFields()}
          </div>
        </>
      );
    }

    return null;
  }
}

NewcastleSurvey.propTypes = {
  articleType: PropTypes.string,
  surveyResponse: PropTypes.shape({
    id: PropTypes.string,
    surveyId: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        questionId: PropTypes.string,
        percentValue: PropTypes.number,
        numberValue: PropTypes.number,
        choiceId: PropTypes.string,
      })
    ),
  }),
  surveyResponseId: PropTypes.string,
  surveyHandler: PropTypes.func,
};

NewcastleSurvey.defaultProps = {
  articleType: undefined,
  surveyResponse: undefined,
  surveyResponseId: undefined,
  surveyHandler: () => {},
};

export default NewcastleSurvey;
