import PropTypes from "prop-types";
import { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { observationalResultOutcomePropType } from "../../models/propTypes";
import { checkboxGroupInput, selectField } from "../basic-input";
import {
  fetchObservationalOutcomeTypes,
  fetchStatisticRatioTypes,
} from "../utilities/request";
import Strata from "./components/Strata";

const defaultUnivariate = () => {
  return {
    strata: "univariate",
    variables: [{ varName: "univariate", outcomes: [] }],
  };
};

const defaultMultivariate = () => {
  return {
    strata: "multivariate",
    variables: [],
  };
};

class ObservationalOutcomes extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      outcomeTypeOptions: [],
      statisticRatioOptions: [],
      statisticRatio: null,
      outcomeTypes: [],
      resultsReported: [],
      univariate: null,
      multivariate: null,
      stratified: [],
      strata: [],
    };
  }

  async componentDidMount() {
    const { outcomeTypes, statisticRatio, strata } = this.props;
    Promise.all([
      fetchObservationalOutcomeTypes().then((r) =>
        this.setState({
          outcomeTypeOptions: r.data,
          outcomeTypes,
        })
      ),
      fetchStatisticRatioTypes().then((r) =>
        this.setState({
          statisticRatioOptions: r.data,
          statisticRatio,
        })
      ),
    ]).then(() =>
      this.setState({ strata }, () => {
        const resultsReported = [];
        if (this.univariateOutcomes()) {
          resultsReported.push("Single Exposure");
        }

        if (this.multivariateOutcomes()) {
          resultsReported.push("Multiple Exposures");
        }

        if (this.stratifiedOutcomes().length > 0) {
          resultsReported.push("Stratified analysis");
        }

        this.setState({
          resultsReported,
          univariate: this.univariateOutcomes(),
          multivariate: this.multivariateOutcomes(),
          stratified: this.stratifiedOutcomes(),
        });
      })
    );
  }

  render = () => {
    const {
      outcomeTypes,
      outcomeTypeOptions,
      resultsReported,
      statisticRatio,
    } = this.state;
    return (
      <Form.Group>
        <h2>Outcomes</h2>
        {this.statisticRatioInput()}

        <br />
        {checkboxGroupInput(
          "Please choose all outcomes that are reported for stratified variables",
          "outcomeTypes",
          outcomeTypes,
          (x) => this.setState({ outcomeTypes: x }),
          outcomeTypeOptions
        )}

        <br />
        {checkboxGroupInput(
          "How were results reported?",
          "resultsReported",
          resultsReported,
          (x) => this.setState({ resultsReported: x }, this.runOnChange),
          ["Single Exposure", "Multiple Exposures", "Stratified analysis"]
        )}

        {this.singleExposureSelected() && (
          <>
            <br />
            <h3>Single Exposure</h3>
            <Strata
              strata={this.univariateOutcomes() || defaultUnivariate()}
              onChange={this.handleUnivariateChange()}
              outcomeTypes={outcomeTypes}
              statisticRatio={statisticRatio}
            />
            <br />
          </>
        )}

        {this.multiExposureSelected() && (
          <>
            <br />
            <h3>Multiple Exposures</h3>
            <Strata
              strata={this.multivariateOutcomes() || defaultMultivariate()}
              onChange={this.handleMultivariateChange()}
              onRemove={this.removeStrata("multivariate")}
              outcomeTypes={outcomeTypes}
              statisticRatio={statisticRatio}
            />
            <br />
          </>
        )}

        {this.stratifiedSelected() && (
          <>
            <br />
            <h3>Stratified analysis</h3>
            <Button onClick={this.addStrata}>Add Strata</Button>
            {this.stratifiedOutcomes().map((strata, i) => (
              <Strata
                key={strata.strata}
                strata={strata}
                onChange={this.handleStrataChange(i)}
                onRemove={this.removeStrata(i)}
                outcomeTypes={outcomeTypes}
                statisticRatio={statisticRatio}
              />
            ))}
            <br />
          </>
        )}
        <br />
      </Form.Group>
    );
  };

  statisticRatioInput = () => {
    const { statisticRatio, statisticRatioOptions } = this.state;
    return selectField(
      "Statistics Ratio",
      "statisticRatio",
      statisticRatio,
      true,
      statisticRatioOptions,
      this.handleChange
    );
  };

  addStrata = () => {
    const { strata: previousStrata } = this.state;
    const strata = [...previousStrata];
    strata.push({
      strata: null,
      variables: [],
    });
    this.setState({ strata }, this.runOnChange);
  };

  removeStrata = (index) => () => {
    const { strata: previousStrata } = this.state;
    const strata = [...previousStrata].filter((x, i) => i !== index);
    this.setState({ strata }, this.runOnChange);
  };

  handleUnivariateChange = () => (univariateState) => {
    this.setState({ univariate: univariateState }, this.runOnChange);
  };

  handleMultivariateChange = () => (multivariateState) => {
    this.setState({ multivariate: multivariateState }, this.runOnChange);
  };

  handleStrataChange = (index) => (strataState) => {
    const { stratified } = this.state;
    const strata = [...stratified];
    strata[index] = strataState;
    this.setState({ stratified: strata }, this.runOnChange);
  };

  singleExposureSelected = () => {
    const { resultsReported } = this.state;
    return resultsReported.filter((x) => x === "Single Exposure").length > 0;
  };

  multiExposureSelected = () => {
    const { resultsReported } = this.state;
    return resultsReported.filter((x) => x === "Multiple Exposures").length > 0;
  };

  stratifiedSelected = () => {
    const { resultsReported } = this.state;
    return (
      resultsReported.filter((x) => x === "Stratified analysis").length > 0
    );
  };

  runOnChange = () => {
    const { onChange } = this.props;
    const { statisticRatio, univariate, multivariate, stratified } = this.state;
    if (onChange) {
      onChange(
        statisticRatio,
        this.singleExposureSelected() ? univariate : null,
        this.multiExposureSelected() ? multivariate : null,
        this.stratifiedSelected() ? stratified : []
      );
    }
  };

  handleChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.runOnChange
    );
  };

  univariateOutcomes() {
    const { strata } = this.state;
    const outcomes = strata.filter((x) => x.strata === "univariate");
    if (outcomes.length > 0) {
      return outcomes[0];
    }
    return null;
  }

  multivariateOutcomes() {
    const { strata } = this.state;
    const outcomes = strata.filter((x) => x.strata === "multivariate");
    if (outcomes.length > 0) {
      return outcomes[0];
    }
    return null;
  }

  stratifiedOutcomes() {
    const { strata } = this.state;
    return strata.filter(
      (x) => x.strata !== "univariate" && x.strata !== "multivariate"
    );
  }
}

ObservationalOutcomes.propTypes = {
  outcomeTypes: PropTypes.arrayOf(PropTypes.string),
  statisticRatio: PropTypes.string,
  strata: PropTypes.arrayOf(
    PropTypes.shape({
      strata: PropTypes.string,
      variables: PropTypes.arrayOf(observationalResultOutcomePropType),
    })
  ),
  onChange: PropTypes.func,
};

ObservationalOutcomes.defaultProps = {
  outcomeTypes: [],
  statisticRatio: undefined,
  strata: [],
  onChange: undefined,
};

export default ObservationalOutcomes;
