import PropTypes from "prop-types";
import { Button, useAccordionButton } from "react-bootstrap";

const CustomToggle = ({ text, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    const item = e.target.parentNode.children[0];
    if (item.innerText.includes("+ expand", 0)) {
      item.innerText = item.innerText.replace("+ expand", "- collapse");
    } else {
      item.innerText = item.innerText.replace("- collapse", "+ expand");
    }
  });

  return (
    <Button
      onClick={decoratedOnClick}
      style={{ cursor: "pointer", paddingBottom: "0", paddingTop: "0" }}
    >
      {text}
    </Button>
  );
};

CustomToggle.propTypes = {
  text: PropTypes.string,
  eventKey: PropTypes.string,
};

CustomToggle.defaultProps = {
  text: undefined,
  eventKey: undefined,
};

export default CustomToggle;
