import { FC, ReactElement } from "react";
import { RouteComponentProps } from "react-router";
import NewArticle from "../NewArticle";

type PathParams = {
  id: string;
};

export type EditObservationalResultProps = RouteComponentProps<PathParams>;

const EditObservationalResult: FC<EditObservationalResultProps> = (
  props: EditObservationalResultProps
): ReactElement => {
  const { match } = props;
  return (
    <NewArticle
      formType="edit"
      index="observational-result"
      articleId={match?.params?.id}
      {...props}
    />
  );
};

export default EditObservationalResult;
